import * as React from 'react';
import { Component, Fragment } from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Avatar from '@mui/material/Avatar';

export class Answer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            domanda: this.props.domanda,
            showCorrectAnswer: this.props.showCorrectAnswer,
            nextQuestion: this.props.nextQuestion,
            selectedRisposte: this.props.selectedRisposte
        }
    }

    componentDidUpdate(propsPrecedenti) {
        if (this.props.showCorrectAnswer !== propsPrecedenti.showCorrectAnswer) {
            this.setState({
                showCorrectAnswer: this.props.showCorrectAnswer
            })
        }

        if (this.props.nextQuestion !== propsPrecedenti.nextQuestion) {
            this.setState({
                nextQuestion: this.props.nextQuestion
            })
        }

        if (this.props.selectedRisposte !== propsPrecedenti.selectedRisposte) {
            this.setState({
                selectedRisposte: this.props.selectedRisposte
            })
        }

        if (this.props.domanda !== propsPrecedenti.domanda) {
            this.setState({
                domanda: this.props.domanda
            })
        }
    }

    selectDomanda = (domanda, index) => {
        this.props.selectDomanda(domanda, index)
    }

    changeColorAnswer = (choice) => { /*Cambia il colore della card se la risposta è corretta (o meno) */
        if (this.state.showCorrectAnswer === true) {
            if (this.state.nextQuestion === true) {
                if (this.state.domanda.correctAnswer.includes(choice.value)) {
                    return '#009f07' //Verde- risposta corretta
                }
                if (this.state.selectedRisposte.includes(choice.value) && (!this.state.domanda.correctAnswer.includes(choice.value))) {
                    return '#d10505' //Rosso- risposta sbagliata
                }
            }
            else {
                if (this.state.selectedRisposte.includes(choice.value)) {
                    return '#b8b8b8' //Grigio quando è selezionata
                }
                else {

                    return 'white'//'#003A6C' //Blu se è alto contrasto
                }
            }
        }
        if (this.state.showCorrectAnswer === false) {
            if (this.state.selectedRisposte.includes(choice.value)) {
                return '#b8b8b8' //Grigio quando è selezionata
            }
            else {

                return 'white' //Blu se è alto contrasto
            }
        }
    }
    changeColorAnswerText = (choice) => { /*Cambia il colore del testo della card se la risposta è corretta (o meno) */
        if ((this.state.showCorrectAnswer === true && this.state.selectedRisposte.includes(choice.value)) ||
            (this.state.showCorrectAnswer === true && this.state.domanda.correctAnswer.includes(choice.value))) {
            if (this.state.nextQuestion === true) { //E se hai cliccato 'avanti/prosegui
                return 'white' //Se la risposta è corretta o semplicemente selezionata ed è abilitata l'impostazioni di visualizza risposta
            }
            else {

                return 'black'

            }
        } else { //Se non è abilitata la visualizazzione della risposta corretta

            return 'black'; //Altrimenti nere

        }
    }

    render() {
        return (
            <Grid container >
                {this.state.domanda.choices.map((choice, index) => (
                    <Fragment key={index}>
                        {this.state.domanda.type === "radiogroup" && (//Tipo di domanda TESTO
                            <Grid key={"radio" + index} item xs={12}>
                                <Card sx={{ mt: 3, display: 'flex', alignItems: 'center', backgroundColor: this.changeColorAnswer(choice) }}
                                    className={`cardDomanda ${this.state.showCorrectAnswer === true && this.state.nextQuestion === true ? "" : "showAnswerHoverEffect"}`}
                                    onClick={() => this.selectDomanda(choice, index)}>
                                    <Box display={{ xs: 'flex', md: 'none' }}>
                                        <Avatar sx={{ bgcolor: 'black', width: '20px', height: '20px', m: 1, p: 1 }}>
                                            <Typography variant="body2">
                                                {(index + 10).toString(36).toUpperCase()}
                                            </Typography>
                                        </Avatar>
                                    </Box>

                                    <Box display={{ xs: 'none', md: 'flex' }}>
                                        <Avatar sx={{ bgcolor: 'black', ml: 2 }}>
                                            {(index + 10).toString(36).toUpperCase()}
                                        </Avatar>
                                    </Box>

                                    {/*Testo su dispositivi di medie-grandi dimensioni */}
                                    <Typography display={{ xs: 'none', md: 'flex' }} variant="h5" sx={{ overflowX: "auto", p: 2, ml: 3, color: this.changeColorAnswerText(choice) }}
                                        dangerouslySetInnerHTML={{
                                            __html: choice.text
                                        }}>
                                    </Typography>

                                    {/*Testo su dispositivi mobile e tablet*/}
                                    <Typography display={{ xs: 'flex', md: 'none' }} variant="body2" sx={{ p: { xs: 1, sm: 2 }, ml: 1, overflowX: "auto", fontWeight: 'bold', color: this.changeColorAnswerText(choice) }}
                                        dangerouslySetInnerHTML={{ __html: choice.text }}>
                                    </Typography>


                                </Card>
                            </Grid>
                        )}

                        {this.state.domanda.type === "imagepicker" && ( //Tipo di domanda IMMAGINI
                            <Grid key={"image" + index} item xs={6} sx={{ p: 2 }}>
                                <Card p={{ xs: 0, md: 2 }} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: this.changeColorAnswer(choice) }}
                                    className={`cardDomanda ${this.state.showCorrectAnswer === true && this.state.nextQuestion === true ? "" : "showAnswerHoverEffect"}`}
                                    onClick={() => this.selectDomanda(choice, index)}>
                                    <div style={{ margin: '10px' }} dangerouslySetInnerHTML={{ __html: choice.text }} >
                                    </div>
                                    <Box >
                                        <Avatar sx={{ bgcolor: 'black', my: 2 }}>
                                            {(index + 10).toString(36).toUpperCase()}
                                        </Avatar>
                                    </Box>
                                </Card>
                            </Grid>
                        )}

                    </Fragment>
                ))}

            </Grid>
        )
    }
}
