import * as React from 'react';
import { Component, createRef, Fragment } from "react";
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';
import { Divider } from '@mui/material';
import QuizIcon from '@mui/icons-material/Quiz';
import StartIcon from '@mui/icons-material/Start';

export class Sezione extends Component {

    constructor(props) {
        super(props);
        this.quizSelezionatiRef = createRef();
        this.quizDiPartenzaRef = createRef();
        this.state = {
            tipoTest: this.props.tipoTest,
            sezione: this.props.sezione,
            numeroQuizSelezionati: this.props.sezione.quizDisponibili,
            indexSezione: this.props.indexSezione,
            quizDiPartenza: 0,
            maxQuizDiPartenza: this.props.sezione.quizDisponibili,
            percentualiQuiz: [],
            percentualeQuizCompletamento: undefined
        }
    }

    componentDidMount() {
        if (this.props.sezione.numQuiz > this.props.sezione.quizDisponibili) {
            this.setState({
                numeroQuizSelezionati: this.props.sezione.quizDisponibili
            })
        }
        if (this.props.sezione.numQuiz !== undefined) {
            this.setState({
                numeroQuizSelezionati: this.props.sezione.numQuiz,
            })
        }

        if (parseInt(this.props.sezione.numQuiz) > parseInt(this.props.sezione.quizDisponibili)) {
            this.setState({
                numeroQuizSelezionati: this.props.sezione.quizDisponibili
            }, function () {
                if (this.quizSelezionatiRef.current != null)
                    this.quizSelezionatiRef.current.value = this.state.numeroQuizSelezionati;
            })
        }

    }

    componentDidUpdate(propsPrecedenti) {
        if (propsPrecedenti.percentualiQuiz != this.props.percentualiQuiz) {
            this.setState({ percentualiQuiz: this.props.percentualiQuiz }, function () {
                if (this.state.percentualiQuiz[this.state.indexSezione] != -1) {
                    this.setState({
                        percentualeQuizCompletamento: this.state.percentualiQuiz[this.state.indexSezione]
                    })
                }
            })
        }
        if (propsPrecedenti.tipoTest != this.props.tipoTest) {
            this.setState({
                tipoTest: this.props.tipoTest
            }, function () {

                // if (this.state.tipoTest === "Seq") {
                //     this.setState({
                //         numeroQuizSelezionati: this.state.sezione.quizDisponibili
                //     }, function () {
                //         this.props.changeNumeroQuiz(this.state.sezione.quizDisponibili, this.state.indexSezione);
                //         this.quizSelezionatiRef.current.value = this.state.sezione.quizDisponibili;
                //     })
                // }
                // if (propsPrecedenti.tipoTest === 'Seq' && this.state.tipoTest === 'Ese') {
                //     this.setState({
                //         numeroQuizSelezionati: this.state.sezione.numQuiz
                //     }, function () {
                //         this.props.changeNumeroQuiz(this.state.sezione.numQuiz, this.state.indexSezione);
                //         this.quizSelezionatiRef.current.value = this.state.sezione.numQuiz;

                //     })

                // }
            })
        }
    }

    changeNumeroQuiz = (e) => {
        this.setState({
            numeroQuizSelezionati: parseInt(e.target.value)
        }, function () {

            if (this.state.tipoTest === 'Seq') {
                //&& this.state.quizDiPartenza >= this.state.numeroQuizSelezionati
                let maxPartenzaTmp = this.state.sezione.quizDisponibili - this.state.numeroQuizSelezionati;
                let tmpQuizPartenza = this.state.quizDiPartenza;
                if (tmpQuizPartenza > maxPartenzaTmp)
                    tmpQuizPartenza = maxPartenzaTmp - 1;
                if (this.state.numeroQuizSelezionati < this.state.sezione.quizDisponibili) {
                    this.setState({
                        quizPartenza: tmpQuizPartenza,
                        maxQuizDiPartenza: maxPartenzaTmp
                    })
                    this.quizDiPartenzaRef.current.value = tmpQuizPartenza;
                } else {
                    this.setState({
                        quizPartenza: 0
                    })
                    this.quizDiPartenzaRef.current.value = 0;
                }
            }
            this.quizSelezionatiRef.current.value = parseInt(e.target.value);
            this.props.changeNumeroQuiz(this.state.numeroQuizSelezionati, this.state.indexSezione, this.state.quizDiPartenza);
        })
    }
    changeNumeroQuizPartenza = (e) => {

        let quizDiPartenzaTmp = parseInt(e.target.value);
        if (quizDiPartenzaTmp < 0 || isNaN(quizDiPartenzaTmp)) quizDiPartenzaTmp = 0;

        this.setState({
            quizDiPartenza: quizDiPartenzaTmp
        }, function () {

            this.quizDiPartenzaRef.current.value = this.state.quizDiPartenza;
            this.props.changeNumeroQuiz(this.state.numeroQuizSelezionati, this.state.indexSezione, quizDiPartenzaTmp);
        })
    }
    changeNumeroQuizToParent = (e) => { //Fixa il lag, dato che questo viene triggherato all onBlur
        this.props.changeNumeroQuiz(this.state.numeroQuizSelezionati, this.state.indexSezione, this.state.quizDiPartenza);
    }
    changeQuizPartenza = (e) => { //Fixa il lag, dato che questo viene triggherato all onBlur
        this.props.changeNumeroQuiz(this.state.numeroQuizSelezionati, this.state.indexSezione, this.state.quizDiPartenza);
    }


    render() {
        return (
            <Fragment>
                {this.state.sezione !== undefined ? (
                    <Paper elevation={5} sx={{ height: '100%' }}>
                        <Box sx={{ p: 3, minHeight: '100px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                            <Typography sx={{ textAlign: 'center' }} variant="body2">
                                <b>{this.state.sezione.nomeParte}</b>
                            </Typography>


                            {this.state.tipoTest === 'Sim' && (
                                <Typography variant="body1" sx={{ color: '#003370' }}>
                                    Numero di quesiti somministrati: <b> {this.state.sezione.numQuiz}   </b>
                                </Typography>
                            )}

                            {this.state.tipoTest === 'Ese' && (
                                <Fragment>
                                    <Grid container style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
                                            <QuizIcon sx={{ mr: 2, color: '#003370' }} />
                                            <Typography >
                                                Numero di quiz:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField onBlur={this.changeNumeroQuiz} inputProps={{ style: { textAlign: 'center' } }}
                                                defaultValue={this.state.numeroQuizSelezionati} variant="outlined" inputRef={this.quizSelezionatiRef} />
                                        </Grid>
                                        <Grid item xs={2} sx={{ textAlign: 'end' }}>
                                            <Typography >di {this.state.sezione.quizDisponibili}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Slider size="small"
                                                value={this.state.numeroQuizSelezionati}
                                                min={0} max={parseInt(this.state.sezione.quizDisponibili)} onChange={this.changeNumeroQuiz}
                                                onChangeCommitted={this.changeNumeroQuizToParent} valueLabelDisplay="auto"
                                            />
                                        </Grid>
                                    </Grid>
                                </Fragment>
                            )}

                            {(this.state.tipoTest === 'Seq') && (
                                <Fragment>
                                    <Box> {/*Numero di quiz */}
                                        <Grid container style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
                                                <QuizIcon sx={{ mr: 2, color: '#003370' }} />
                                                <Typography >
                                                    Numero di quiz:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField onBlur={this.changeNumeroQuiz} inputProps={{ style: { textAlign: 'center' } }}
                                                    defaultValue={this.state.numeroQuizSelezionati} variant="outlined" inputRef={this.quizSelezionatiRef} />
                                            </Grid>
                                            <Grid item xs={2} sx={{ textAlign: 'end' }}>
                                                <Typography >di {this.state.sezione.quizDisponibili}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Slider size="small"
                                                    value={this.state.numeroQuizSelezionati}
                                                    min={0} max={parseInt(this.state.sezione.quizDisponibili)} onChange={this.changeNumeroQuiz}
                                                    onChangeCommitted={this.changeNumeroQuizToParent} valueLabelDisplay="auto"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    {/*Parti dal quiz*/}
                                    <Box>
                                        <Grid container style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
                                                <StartIcon sx={{ mr: 2, color: '#003370' }} />
                                                <Typography >
                                                    Parti dal quiz:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField onBlur={this.changeNumeroQuizPartenza} inputProps={{ style: { textAlign: 'center' } }}
                                                    defaultValue={this.state.quizDiPartenza} variant="outlined" inputRef={this.quizDiPartenzaRef} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Slider size="small" value={this.state.quizDiPartenza} disabled={this.state.numeroQuizSelezionati == this.state.sezione.quizDisponibili} min={0} max={this.state.maxQuizDiPartenza}
                                                    onChange={this.changeNumeroQuizPartenza} onChangeCommitted={this.changeQuizPartenzaToParent} valueLabelDisplay="auto" />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Fragment>
                            )}

                            {(this.state.tipoTest == 'Seq') && (
                                <Fragment>
                                    <Divider style={{ width: "100%" }} sx={{ my: 1 }} />
                                    {this.state.percentualeQuizCompletamento != undefined ? (
                                        <Typography>
                                            Percentuale di completamento: <b>{this.state.percentualeQuizCompletamento}%</b>
                                        </Typography>
                                    ) : (
                                        <Typography>
                                            Percentuale di completamento: <b>0%</b>
                                        </Typography>
                                    )}
                                </Fragment>
                            )}

                        </Box>
                    </Paper>
                ) : (
                    <Skeleton variant="rectangular" sx={{ width: '100%', borderRadius: '10px' }} height={150} />
                )}
            </Fragment>
        )
    }
}

