import * as React from 'react';
import { Component } from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import PauseIcon from '@mui/icons-material/Pause';
import Tooltip from '@mui/material/Tooltip';
import { CountdownCircleTimer } from 'react-countdown-circle-timer'

export class Timer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            typeQuestionario: this.props.typeQuestionario,
            timer: this.props.timer,
            timePaused: false,
            remainingTime: this.props.timer,
            initialTimer: this.props.timer,
            sezione: this.props.sezione,
            key: 0,
        }
    }

    componentDidUpdate(propsPrecedenti) {
        if (this.props.timer !== propsPrecedenti.timer) {
            this.setState({
                timer: this.props.timer
            })
        }
        // if (this.props.sezione !== propsPrecedenti.sezione) {

        //     this.setState({
        //         sezione: this.props.sezione,
        //         timer: this.props.sezione.tempMax * 60,
        //         remainingTime: this.props.sezione.remainingTime,
        //         initialTimer: this.props.sezione.remainingTime,
        //         key: this.state.key + 1

        //     })
        // }
    }

    convertMinutesToSeconds = (time) => {  /*Converto i secondi in formato MM:SS */
        let minutes = Math.floor(time / 60);
        let seconds = time - minutes * 60;
        if (minutes < 10) minutes = '0' + minutes
        if (seconds < 10) seconds = '0' + seconds
        return (minutes + "." + seconds)
    }
    pauseTime = () => {
        if (this.state.typeQuestionario === 'Ese') {
            this.setState({
                timePaused: !this.state.timePaused,
            })
        }
    }


    render() {
        return (
            <>
                {this.state.typeQuestionario === 'Ese' && (
                    <Tooltip arrow title='Metti in pausa'>
                        <Box onClick={this.pauseTime} sx={{ cursor: 'pointer' }}>
                            <CountdownCircleTimer
                                size={100}
                                key={this.state.key}
                                duration={this.state.timer}
                                colors={["#003A6C"]}
                                trailColor={"#196BB4"}
                                strokeWidth={12}
                                isPlaying={!this.state.timePaused}
                                initialRemainingTime={this.state.initialTimer}
                                onUpdate={(remainingTime) => {
                                    this.setState({
                                        remainingTime: remainingTime
                                    }, function () {
                                        this.props.changeRemainingTime(remainingTime)
                                    })
                                }}
                                onComplete={() => {
                                    this.props.onTimeFinished();
                                    return { shouldRepeat: false }
                                }}
                            >
                                {({ remainingTime }) =>
                                    <div>
                                        {this.state.timePaused === false && (
                                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                <Typography sx={{ color: 'white', cursor: 'pointer' }} variant='h6'>
                                                    {this.convertMinutesToSeconds(remainingTime)}
                                                </Typography>
                                                <PauseIcon sx={{ color: 'white', fontSize: '1rem' }} />
                                            </Box>
                                        )}
                                        {this.state.timePaused === true && (
                                            <PauseIcon sx={{ color: 'white' }} />
                                        )}
                                    </div>
                                }
                            </CountdownCircleTimer>
                        </Box>
                    </Tooltip>
                )}
                {this.state.typeQuestionario === 'Sim' && ( //Timer senza pausa
                    <Tooltip arrow title='Nella modalità simulazione non puoi sospendere la prova'>
                        <Box >
                            <CountdownCircleTimer
                                size={100}
                                key={this.state.key}
                                duration={this.state.timer}
                                colors={["#003A6C"]}
                                trailColor={"#196BB4"}
                                strokeWidth={12}
                                isPlaying={!this.state.timePaused}
                                initialRemainingTime={this.state.initialTimer}
                                onUpdate={(remainingTime) => {
                                    this.setState({
                                        remainingTime: remainingTime
                                    }, function () {
                                        this.props.changeRemainingTime(remainingTime)
                                    })
                                }}
                                onComplete={() => {
                                    this.props.onTimeFinished();
                                    return { shouldRepeat: false }
                                }}

                            >
                                {({ remainingTime }) =>
                                    <div>
                                        {this.state.timePaused === false && (
                                            <Typography sx={{ color: 'white', cursor: 'context-menu' }} variant='h6'>
                                                {this.convertMinutesToSeconds(remainingTime)}
                                            </Typography>
                                        )}
                                        {this.state.timePaused === true && (
                                            <PauseIcon sx={{ color: 'white' }} />
                                        )}
                                    </div>
                                }
                            </CountdownCircleTimer>
                        </Box>
                    </Tooltip>
                )}

                <Dialog open={this.state.timePaused}>
                    <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', p: 5 }}>
                        <Typography variant="h3">In Pausa</Typography>
                        <Button variant="contained" sx={{ mt: 2, color: 'white', px: 5 }} onClick={this.pauseTime}>Torna al quiz</Button>
                    </DialogContent>
                </Dialog>

            </>
        )
    }
}