import * as React from 'react';
import { Component, Fragment } from "react";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom"
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import { red, green, blue } from '@mui/material/colors';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import Container from '@mui/material/Container';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
//import FacebookIcon from '@mui/icons-material/Facebook';
import PieGraph from '../components/pieGraph';
import { Virtuoso } from 'react-virtuoso'
import DonutLargeIcon from '@mui/icons-material/DonutLarge';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import InputAdornment from '@mui/material/InputAdornment';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import EmailIcon from '@mui/icons-material/Email';
import { Divider } from '@mui/material';

import Logo from '../edizioni-simone-logo-blu.png'
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import Link from '@mui/material/Link';
import { QuestionarioAPI } from '../api/questionarioApi';

export class StatisticheQuizSvolto extends Component {

    constructor(props) {
        super(props);
        this.state = {
            questionarioCompletato: this.props.questionario,
            risposteCorrette: 0,
            risposteErrate: 0,
            risposteOmesse: 0,
            punteggio: 0,
            tempoDiCompletamento: "0",
            showRiepilogoDomande: false,
            idQuestionario: this.props.idQuestionario !== undefined ? this.props.idQuestionario : -1,
            idVolume: this.props.idVolume !== undefined ? this.props.idVolume : -1,
            idUtente: this.props.idUtente !== undefined ? this.props.idUtente : 99,
            isGratuito: false,
            typeQuestionario: 'Sim',
            sogliaSufficienza: 0,
            esito: false,
            punteggioTotale: 0,
            nomeQuestionario: props.nomeQuestionario,
            showGraficoOnMobileOpen: false,

            emailPageOpen: true,
            consensoPrivacy: false,
            consensoNewsletter: false,
            emailUtente: '',

            dataInizio: this.props.dataInizio !== undefined ? this.props.dataInizio : new Date().getTime(),
            dataFine: this.props.dataFine !== undefined ? this.props.dataFine : new Date().getTime()
        }
    }

    componentDidMount() {
        let diffMilliseconds = this.props.dataFine - this.props.dataInizio;

        let diffSeconds = Math.floor(diffMilliseconds / 1000);
        let diffMinutes = Math.floor(diffSeconds / 60);
        let diffHours = Math.floor(diffMinutes / 60);
        diffSeconds = (diffSeconds % 60).toString().padStart(2, '0');
        diffMinutes = (diffMinutes % 60).toString().padStart(2, '0');
        let tempoCompletamentoTmp = diffHours.toString() + ":" + diffMinutes.toString() + ":" + diffSeconds.toString();
        this.setState({
            idQuestionario: this.props.idQuestionario,
            idVolume: this.props.idVolume,
            typeQuestionario: this.props.type,
            isGratuito: this.props.isGratuito,
            sogliaSufficienza: this.props.sogliaSufficienza,
            tempoDiCompletamento: tempoCompletamentoTmp
        }, function () {
            this.calculateRisposteCorrette();
            this.calculateRisposteErrate();
            this.calculateRisposteOmesse();

            this.saveQuestionario();

            // if (this.state.typeQuestionario === 'Sim') {
            //     this.calculateEsito();
            // } else {
            //     this.saveQuestionario();
            // }
        });
    }

    saveQuestionario = () => {
        let quizTmp = this.state.questionarioCompletato;
        let risposteCorretteTmp = 0, risposteErrateTmp = 0, risposteOmesseTmp = 0;
     

        for (let i = 0; i < quizTmp.length; i++) {
            //OMESSE
            if (quizTmp[i].givenAnswer.length === 0) {
                risposteOmesseTmp++;
            }
            //ERRATE
            if (!quizTmp[i].givenAnswer.some(item => quizTmp[i].correctAnswer.includes(item)) && quizTmp[i].givenAnswer.length > 0) {
                risposteErrateTmp++;
            }
            //CORRETTE
            if (quizTmp[i].givenAnswer.some(item => quizTmp[i].correctAnswer.includes(item))) {
                risposteCorretteTmp++;
            }
        }

       /* for (let i = 0; i < quizTmp.length; i++) {
            if (quizTmp[i].givenAnswer.length > 0 && this.arraysAreEqual(quizTmp[i].givenAnswer, quizTmp[i].correctAnswer) == true)
                risposteCorretteTmp += 1;
            else if (quizTmp[i].givenAnswer.length === 0)
                risposteOmesseTmp += 1;
            else if (quizTmp[i].givenAnswer.length > 0 && this.arraysAreEqual(quizTmp[i].givenAnswer, quizTmp[i].correctAnswer) == false) {
                risposteErrateTmp += 1;

            }
        }*/
        let utenteIDTmp = this.state.idUtente;
        if (localStorage.getItem("id") != null)
            utenteIDTmp = localStorage.getItem("id")

        let dataInizioTimeStamp = new Date(this.state.dataInizio).toISOString();
        let dataFineTimeStamp = new Date(this.state.dataFine).toISOString();
        let data = {
            "sogliaSufficienza": this.state.sogliaSufficienza,
            "nomeQuestionario": this.state.nomeQuestionario,
            "idQuestionario": parseInt(this.state.idQuestionario),
            "idVolume": this.state.idVolume,
            "idUtente": "" + utenteIDTmp,
            "superato": this.state.esito,
            "type": this.state.typeQuestionario,
            "risposteOmesse": risposteOmesseTmp,
            "risposteErrate": risposteErrateTmp,
            "risposteCorrette": risposteCorretteTmp,
            "quiz": this.state.questionarioCompletato,
            "dataInizio": dataInizioTimeStamp,
            "dataFine": dataFineTimeStamp
        }
        let requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        };

        if (!this.state.isGratuito) {
            fetch(process.env.REACT_APP_FETCH_URL + 'questionario/archive/add', requestOptions)
                .then(response => response.json())
                .then(res => {
                    if (this.state.typeQuestionario === "Sim") {
                        //Troncamento al secondo numero dopo la virgola (non viene arrotondato)
                        let punteggioTotaleTmp = (res?.data?.punteggioTotale ?? 0).toString();
                        let indiceVirgola = punteggioTotaleTmp.indexOf('.');
                        if (indiceVirgola != -1) {
                            punteggioTotaleTmp = punteggioTotaleTmp.substring(0, indiceVirgola + 3);
                            while (punteggioTotaleTmp.length < indiceVirgola + 3) {
                                punteggioTotaleTmp += '0';
                            }
                        }
                        this.setState({
                            esito: res?.data?.superato ?? false,
                            punteggioTotale: punteggioTotaleTmp ?? 0
                        })
                    }
                    if (res.status === 403) {
                        window.location.href = process.env.REACT_APP_PUBLIC_URL + process.env.REACT_APP_PREFIX + "/sessione-scaduta";
                    }

                })
        }
    }
    saveQuestionarioGratuito = () => {



        let quizTmp = this.state.questionarioCompletato;
        let risposteCorretteTmp = 0, risposteErrateTmp = 0, risposteOmesseTmp = 0;
        for (let i = 0; i < quizTmp.length; i++) {
            if (quizTmp[i].givenAnswer.some(item => quizTmp[i].correctAnswer.includes(item))) 
                risposteCorretteTmp += 1;
            else if (!quizTmp[i].givenAnswer.some(item => quizTmp[i].correctAnswer.includes(item)) && quizTmp[i].givenAnswer.length > 0) 
                risposteOmesseTmp += 1;
            else if (quizTmp[i].givenAnswer.length === 0) {
                risposteErrateTmp += 1;
            }
        }

        let dataInizioTimeStamp = new Date(this.state.dataInizio).toISOString();
        let dataFineTimeStamp = new Date(this.state.dataFine).toISOString();
        let data = {
            "sogliaSufficienza": this.state.sogliaSufficienza,
            "nomeQuestionario": this.state.nomeQuestionario,
            "idQuestionario": parseInt(this.state.idQuestionario),
            "idVolume": this.state.idVolume,
            //"idUtente": "" + utenteIDTmp,
            "superato": this.state.esito,
            "type": this.state.typeQuestionario,
            "risposteOmesse": risposteOmesseTmp,
            "risposteErrate": risposteErrateTmp,
            "risposteCorrette": risposteCorretteTmp,
            "quiz": this.state.questionarioCompletato,
            "dataInizio": dataInizioTimeStamp,
            "dataFine": dataFineTimeStamp
        }

        let dataObj = JSON.stringify(data);
        QuestionarioAPI.archiveGratuito(dataObj)
            .then((result) => {
                //Troncamento al secondo numero dopo la virgola (non viene arrotondato)
                let punteggioTotaleTmp = (result?.data?.punteggioTotale ?? 0).toString();
                let indiceVirgola = punteggioTotaleTmp.indexOf('.');
                if (indiceVirgola != -1) {
                    punteggioTotaleTmp = punteggioTotaleTmp.substring(0, indiceVirgola + 3);
                    while (punteggioTotaleTmp.length < indiceVirgola + 3) {
                        punteggioTotaleTmp += '0';
                    }
                }
                this.setState({
                    esito: result?.data?.superato ?? false,
                    punteggioTotale: punteggioTotaleTmp ?? 0
                })
            })
            .catch((err) => { })

    }

    calculateRisposteCorrette = () => {
        let quizTmp = this.state.questionarioCompletato;
        let risposteCorretteTmp = 0;
        let punteggioTmp = this.state.punteggio;
        for (let i = 0; i < quizTmp.length; i++) {
            if (quizTmp[i].givenAnswer.some(item => quizTmp[i].correctAnswer.includes(item))) {
                // if (quizTmp[i].correctAnswer.includes(quizTmp[i].givenAnswer)){
                risposteCorretteTmp++;
                punteggioTmp += quizTmp[i].puntiCorretta;           
            }
        }
        this.setState({
            risposteCorrette: risposteCorretteTmp,
            punteggio: punteggioTmp
        })
    }
    calculateRisposteErrate = () => {
        let quizTmp = this.state.questionarioCompletato;
        let risposteErrateTmp = 0;
        let punteggioTmp = this.state.punteggio;
        for (let i = 0; i < quizTmp.length; i++) {
            if (!quizTmp[i].givenAnswer.some(item => quizTmp[i].correctAnswer.includes(item)) && quizTmp[i].givenAnswer.length > 0) {
          //  if ((!quizTmp[i].givenAnswer.includes(quizTmp[i].correctAnswer)) && quizTmp[i].givenAnswer.length > 0) { //(this.arraysAreEqual(quizTmp[i].givenAnswer, quizTmp[i].correctAnswer) === false && quizTmp[i].givenAnswer.length > 0) {
                risposteErrateTmp++;
                punteggioTmp += quizTmp[i].puntiErrata;
            }
        }
        this.setState({
            risposteErrate: risposteErrateTmp,
            punteggio: punteggioTmp
        })
    }
    calculateRisposteOmesse = () => {
        let quizTmp = this.state.questionarioCompletato;
        let risposteOmesseTmp = 0;
        let punteggioTmp = this.state.punteggio;

        for (let i = 0; i < quizTmp.length; i++) {
            if (quizTmp[i].givenAnswer.length === 0) {
                risposteOmesseTmp++;
                punteggioTmp += quizTmp[i].puntiOmessa;
            }
        }
        this.setState({
            risposteOmesse: risposteOmesseTmp,
            punteggio: punteggioTmp
        })
    }
    /*calculateEsito = () => { Inutilizzato, l'esito viene calcolato dal BE

        let quizTmp = this.state.questionarioCompletato;
        let punteggioTmp = this.state.punteggio;
        for (let i = 0; i < quizTmp.length; i++) {
            if (this.arraysAreEqual(quizTmp[i].givenAnswer, quizTmp[i].correctAnswer) === true)
                punteggioTmp += quizTmp[i].puntiCorretta;
            if (quizTmp[i].givenAnswer.length === 0)
                punteggioTmp += quizTmp[i].puntiOmessa;
            if (this.arraysAreEqual(quizTmp[i].givenAnswer, quizTmp[i].correctAnswer) === false && quizTmp[i].givenAnswer.length > 0) {
                punteggioTmp += quizTmp[i].puntiErrata;

            }
        }
        this.setState({
            punteggio: punteggioTmp
        }, function () {

            if (this.state.punteggio >= this.state.sogliaSufficienza) {
                this.setState({
                    esito: true,
                }, function () {
                    this.saveQuestionario();
                })
            }
            else {
                this.setState({
                    esito: false,
                }, function () {
                    this.saveQuestionario();
                })
            }
        })

    }*/


    arraysAreEqual = (arr1, arr2) => {
        if (arr1 !== undefined && arr2 !== undefined) {
            if (arr1.length !== arr2.length) return false;
            arr1 = arr1.slice().sort();
            arr2 = arr2.slice().sort();
            return arr1.every((value, index) => value === arr2[index]);
        } else return false
    }

    rowDomanda = (index) => {
        return (
            <Box px={{ sx: 0, md: 3 }} sx={{ my: 2 }}>

                <Paper elevation={5} sx={{ p: 5, mx: 2 }}>
                    <Typography variant="subtitle2" >
                        Risposta n. {index + 1}
                    </Typography>
                    <Box sx={{ display: 'flex' }}>
                        <Typography display={{ xs: 'none', md: 'flex' }} variant="h5">
                            <div style={{ overflowY: 'auto', maxHeight: '20vh' }} dangerouslySetInnerHTML={{ __html: this.state.questionarioCompletato[index].title }}></div>
                        </Typography>
                        <Typography display={{ xs: 'flex', md: 'none' }} sx={{ mb: 2, mt: 1 }} variant="h6">
                            <div style={{ overflowY: 'auto', maxHeight: '20vh' }} dangerouslySetInnerHTML={{ __html: this.state.questionarioCompletato[index].title }}></div>
                        </Typography>
                    </Box>
                    {this.state.questionarioCompletato[index].choices.map((choice, i) => (
                        <Box key={i} sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center', mt: { xs: 2, md: 2 } }} >
                            <Typography variant="body1" sx={{ mr: 1 }}>
                                {(i + 10).toString(36).toUpperCase() + ")"}
                            </Typography>
                            <div style={{ overflowY: 'auto', maxHeight: '20vh' }} dangerouslySetInnerHTML={{ __html: choice.text }} />
                            <Typography variant="subtitle2" sx={{ color: this.changeColorAnswerText(this.state.questionarioCompletato[index], choice) }}>
                                {this.state.questionarioCompletato[index]?.givenAnswer.indexOf(choice.value) != -1 && this.state.questionarioCompletato[index]?.correctAnswer.indexOf(choice.value) == -1 && ( // && !this.state.questionarioCompletato[index]?.correctAnswer.includes(choice.value) &&
                                    <span style={{ marginLeft: '10px' }}>(Risposta Fornita - Errata)</span>
                                )}
                                {this.state.questionarioCompletato[index]?.givenAnswer.indexOf(choice.value) == -1 && this.state.questionarioCompletato[index]?.correctAnswer.indexOf(choice.value) != -1 && (
                                    <span style={{ marginLeft: '10px' }}>(Corretta)</span>
                                )}
                                {this.state.questionarioCompletato[index]?.givenAnswer.indexOf(choice.value) != -1 && this.state.questionarioCompletato[index]?.correctAnswer.indexOf(choice.value) != -1 && (
                                    <span style={{ marginLeft: '10px' }}>(Risposta Fornita - Corretta)</span>
                                )}

                                {/* {this.state.questionarioCompletato[index]?.correctAnswer.includes(choice.value) && !this.state.questionarioCompletato[index]?.givenAnswer.includes(choice.value)(
                                    <span style={{ marginLeft: '10px' }}>(Corretta)</span>
                                )}

                                {this.state.questionarioCompletato[index]?.givenAnswer.includes(choice.value) && this.state.questionarioCompletato[index]?.correctAnswer.includes(choice.value) && (
                                    <span style={{ marginLeft: '10px' }}>(Risposta Fornita - Corretta)</span>
                                )}

                             */}
                            </Typography>

                        </Box>
                    ))
                    }



                    {
                        this.state.questionarioCompletato[index].givenAnswer.length === 0 && (
                            <Typography variant="subtitle1" sx={{ mt: 2, color: 'red' }}>Risposta Omessa</Typography>
                        )
                    }
                </Paper >
            </Box >
        )
    }

    renderDomande = (domanda, index) => {
        return (

            <Paper elevation={5} sx={{ my: 3, p: 5 }}>
                <Typography variant="subtitle2" >
                    Risposta n. {index + 1}
                </Typography>
                <Box sx={{ display: 'flex' }}>
                    <Typography display={{ xs: 'none', md: 'flex' }} variant="h5">
                        <div style={{ overflowY: 'auto', maxHeight: '20vh' }} dangerouslySetInnerHTML={{ __html: domanda.title }}></div>
                    </Typography>
                    <Typography display={{ xs: 'flex', md: 'none' }} sx={{ mb: 2, mt: 1 }} variant="h6">
                        <div style={{ overflowY: 'auto', maxHeight: '20vh' }} dangerouslySetInnerHTML={{ __html: domanda.title }}></div>
                    </Typography>
                </Box>
                {domanda.choices.map((choice, i) => (
                    <Typography variant="body1" key={i} sx={{ mt: 1, color: this.changeColorAnswerText(domanda, choice) }}>
                        {(i + 10).toString(36).toUpperCase()})  {choice.text}
                        {domanda.givenAnswer.includes(choice.value) && !domanda.correctAnswer.includes(choice.value) && (
                            <span style={{ marginLeft: '10px' }}>(Risposta Fornita - Errata)</span>
                        )}
                        {domanda.correctAnswer.includes(choice.value) && !domanda.givenAnswer.includes(choice.value)(
                            <span style={{ marginLeft: '10px' }}>(Corretta)</span>
                        )}

                        {domanda.givenAnswer.includes(choice.value) && domanda.correctAnswer.includes(choice.value) && (
                            <span style={{ marginLeft: '10px' }}>(Risposta Fornita - Corretta)</span>
                        )}
                    </Typography>
                ))}
                {domanda.givenAnswer.length === 0 && (
                    <Typography variant="subtitle1" sx={{ mt: 1, color: 'red' }}>Risposta Omessa</Typography>
                )}
            </Paper>
        )
    }
    changeColorAnswerText = (domanda, choice) => {
        if (choice.value === domanda.givenAnswer) { //Se è la domanda selezionata
            if (!domanda.correctAnswer.includes(choice.value)) {
                return red[600]
            }
        }

        if (domanda.correctAnswer.includes(choice.value))
            return green[600]
    }
    goToRiepilogo = () => {
        this.setState({ showRiepilogoDomande: true })
    }
    goToStatistiche = () => {
        this.setState({ showRiepilogoDomande: false })
    }

    openDialogPieChart = () => {
        this.setState({
            showGraficoOnMobileOpen: !this.state.showGraficoOnMobileOpen
        })
    }



    setEmailUtente = (e) => {
        let emailTmp = e.target.value;
        emailTmp = emailTmp.replace(/\s+/g, '')
        this.setState({
            emailUtente: emailTmp
        })
    }
    changeConsensoPrivacy = () => {
        this.setState({
            consensoPrivacy: !this.state.consensoPrivacy
        })
    }
    changeConsensoNewsletter = () => {
        this.setState({
            consensoNewsletter: !this.state.consensoNewsletter
        })
    }
    saveNewEmail = () => {
        let requestOptions = {
            method: 'POST',
            body: new URLSearchParams({
                email: this.state.emailUtente,
                consenso_privacy: this.state.consensoPrivacy,
                consenso_newsletter: this.state.consensoNewsletter
            })
        };


        fetch(process.env.REACT_APP_FETCH_URL + 'contattoMailingList/add', requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.success === true) {
                    this.setState({
                        emailPageOpen: false
                    })

                    this.saveQuestionarioGratuito();

                    //Qui aggiungere il calcolo
                }
            });

    }



    render() {
        return (
            <Fragment>
                <Container sx={{ mt: 4 }}>
                    {(this.state.emailPageOpen === true && this.state.isGratuito) ? (
                        <Box sx={{ height: '80vh' }} display={"flex"} flexDirection={"column"} justifyContent={"center"}>
                            <Box sx={{ mb: 5 }} display="flex" justifyContent={"center"}>
                                <Link target="_blank" alt="Edizioni Simone" href="https://edizioni.simone.it/" underline="none">
                                    <img unselectable="on" draggable="false" alt="Logo Simone Edizioni" width={300} src={Logo}></img>
                                </Link>
                            </Box>

                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                <Typography sx={{ mb: 2, fontWeight: 'normal' }} variant="body1">Per vedere i risultati del simulatore è necessario inserire il proprio indirizzo email e accettare l'informativa sulla privacy.</Typography>
                                <Typography variant="subtitle2" sx={{ mb: 1 }}>Il tuo indirizzo email*</Typography>
                                <TextField sx={{ width: '100%' }} type="email" placeholder="Campo obbligatorio" value={this.state.emailUtente} onChange={this.setEmailUtente} InputProps={{
                                    startAdornment: <InputAdornment position="start"><EmailIcon /></InputAdornment>,
                                }} />
                                {this.state.emailUtente !== '' && !this.state.emailUtente.includes("@") && (
                                    <Typography sx={{ color: 'red', ml: 1, mt: 1 }}>Per proseguire è necessario inserire un'email valida</Typography>
                                )}
                                <Box sx={{ ml: 2, mt: 1, display: 'flex', alignItems: 'center' }}>
                                    <Checkbox
                                        checked={this.state.consensoPrivacy}
                                        onChange={this.changeConsensoPrivacy}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                    <Typography> Dichiaro di aver letto<a target="_blank" rel="noreferrer" href='https://edizioni.simone.it/informativa-sulla-privacy'> l'informativa sul trattamento dei miei dati personali.</a> <b>(Campo obbligatorio)</b></Typography>
                                </Box>
                                <Box sx={{ ml: 2, mt: 1, display: 'flex', alignItems: 'center' }}>
                                    <Checkbox
                                        checked={this.state.consensoNewsletter}
                                        onChange={this.changeConsensoNewsletter}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                    <Typography>  Acconsento a ricevere offerte speciali, promozioni e comunicazioni commerciali da parte del<a rel="noreferrer" target="_blank" href='https://edizioni.simone.it/'> Gruppo Simone.</a></Typography>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                                    <Button disabled={this.state.consensoPrivacy === false || this.state.emailUtente === '' || !this.state.emailUtente.includes('@')} onClick={this.saveNewEmail} variant="contained">Conferma e prosegui</Button>
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'end', }}>
                                <Divider sx={{ mt: 5, mb: 2 }} />
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Link target="_blank" alt="Edizioni Simone" href="https://edizioni.simone.it/" underline="none">
                                        <img unselectable="on" draggable="false" alt="Logo Simone Edizioni" width={100} src={Logo}></img>
                                    </Link>
                                    <Box sx={{ display: 'flex', alignItems: 'center', color: '#003370' }}>
                                        <Typography variant='subtitle2' sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}>Seguici sui nostri social:</Typography>
                                        <Link target="_blank" alt="Pagina Facebook Edizioni Simone" href="https://it-it.facebook.com/EdizioniSimone/" underline="none">
                                            <FacebookIcon sx={{ fontSize: '2rem' }} />
                                        </Link>
                                        <Link target="_blank" alt="Account Instagram Edizioni Simone" href="https://www.instagram.com/edizionisimone/?hl=it" underline="none">
                                            <InstagramIcon sx={{ fontSize: '2rem', mx: 2 }} />
                                        </Link>
                                        <Link target="_blank" alt="Canale Youtube Edizioni Simone" href="https://www.youtube.com/channel/UC7Sl8bFGpJMiilhozwnMBnQ" underline="none">
                                            <YouTubeIcon sx={{ fontSize: '2.5rem' }} />
                                        </Link>

                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    ) : (
                        this.state.showRiepilogoDomande === true ? (
                            <Fragment>

                                <Box sx={{ height: { xs: '75vh', md: '80vh' }, border: '1px solid #00000021', borderRadius: '10px' }}>
                                    <Virtuoso
                                        style={{ height: '100%' }}
                                        totalCount={this.state.questionarioCompletato.length}
                                        itemContent={index => (this.rowDomanda(index))}
                                    />
                                </Box>
                                {/*Bottoni da dispositivi medio grandi */}
                                <Box display={{ xs: 'none', md: 'flex' }} sx={{ alignItems: 'center', justifyContent: 'space-between', mt: 4 }}>
                                    {this.state.showRiepilogoDomande === true && (
                                        <Button sx={{ px: 5, mr: 2 }} variant="outlined" onClick={this.goToStatistiche} startIcon={<ArrowBackIcon />}>
                                            Torna alle statistiche
                                        </Button>
                                    )}
                                    {this.state.isGratuito === false && (
                                        <GoToImpostazioni idQuestionario={this.state.idQuestionario} idUtente={this.state.idUtente} />
                                    )}

                                </Box>

                                {/*Bottoni da dispositivi mobile */}
                                <Box display={{ xs: 'flex', md: 'none' }} sx={{ flexDirection: 'column', mt: 2 }}>
                                    {this.state.showRiepilogoDomande === true && (
                                        <Button sx={{ mt: 1, mb: 1, px: 5 }} fullWidth variant="outlined" onClick={this.goToStatistiche} startIcon={<ArrowBackIcon />}>
                                            Torna alle statistiche
                                        </Button>
                                    )}
                                    {this.state.isGratuito === false && (
                                        <GoToImpostazioni idQuestionario={this.state.idQuestionario} idUtente={this.state.idUtente} />
                                    )}

                                </Box>

                            </Fragment>
                        ) : (
                            <Container>
                                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'start' }}> {/*Esito questionario + n.risposte */}
                                    {/*Esito questionario*/}
                                    {this.state.typeQuestionario === "Sim" && (
                                        <Paper elevation={5} sx={{ py: 5 }}>
                                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                                {this.state.esito === true && (
                                                    <Avatar sx={{ bgcolor: green[800], width: 60, height: 60 }}><DoneIcon /></Avatar>
                                                )}
                                                {this.state.esito === false && (
                                                    <Avatar sx={{ bgcolor: red[600], width: 60, height: 60 }}><CloseIcon /></Avatar>
                                                )}
                                                <Box sx={{ ml: 5 }}>
                                                    <Typography variant="body">Esito simulatore:</Typography>
                                                    {this.state.esito === true && (
                                                        <Typography variant="h3" sx={{ color: green[800] }}>Superato</Typography>

                                                    )}
                                                    {this.state.esito === false && (
                                                        <Typography variant="h3" sx={{ color: red[600] }}>Non Superato</Typography>
                                                    )}
                                                </Box>
                                            </Box>
                                            <Box sx={{ mt: 2, p: 2, flexDirection: 'column', justifyContent: 'start', alignItems: 'start', display: { xs: 'flex', md: 'none' } }}>
                                                {this.state.typeQuestionario === "Sim" && (
                                                    <Typography textAlign={"center"} sx={{ mr: 2 }} variant="body1">Punteggio totale: <b style={{ color: '#003370' }}>{this.state.punteggioTotale}</b></Typography>
                                                )}
                                                <Typography textAlign={"center"} variant="body1">Tempo di completamento: <b style={{ color: '#003370' }}>{this.state.tempoDiCompletamento}</b></Typography>
                                            </Box>
                                        </Paper>
                                    )}
                                    {/*Risposte corrette,errate,omesse */}
                                    <Paper elevation={5} sx={{ p: 4, mt: 2 }}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                <Avatar sx={{ bgcolor: green[800] }}><DoneIcon /></Avatar>
                                                <Typography variant="subtitle2" sx={{ mt: 2, textAlign: 'center' }}>Risposte Corrette</Typography>
                                                <Typography variant="body" >{this.state.risposteCorrette}</Typography>
                                            </Grid>
                                            <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                <Avatar sx={{ bgcolor: red[600] }}><CloseIcon /></Avatar>
                                                <Typography variant="subtitle2" sx={{ mt: 2, textAlign: 'center' }}>Risposte Errate</Typography>
                                                <Typography variant="body" >{this.state.risposteErrate}</Typography>
                                            </Grid>
                                            <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                <Avatar sx={{ bgcolor: blue[700] }}><CloseIcon /></Avatar>
                                                <Typography variant="subtitle2" sx={{ mt: 2, textAlign: 'center' }}>Risposte Omesse</Typography>
                                                <Typography variant="body" >{this.state.risposteOmesse}</Typography>
                                            </Grid>
                                            <Grid item xs={12} sx={{ display: { xs: 'flex', md: 'none' }, justifyContent: 'center' }}>
                                                <Button fullWidth variant="outlined" endIcon={<DonutLargeIcon />} onClick={this.openDialogPieChart}>Mostra grafico</Button>
                                            </Grid>

                                        </Grid>

                                    </Paper>


                                </Box>
                                <Paper elevation={5} sx={{ height: '100%', mt: 2, flexDirection: 'column', display: { xs: 'none', md: 'flex' }, width: '100%' }} >
                                    <Box sx={{ mt: 2, justifyContent: 'center', alignItems: 'center', display: { xs: 'none', md: 'flex' } }}>
                                        {this.state.typeQuestionario === "Sim" && (
                                            <Typography textAlign={"center"} sx={{ mr: 2 }} variant="body1">Punteggio totale: <b style={{ color: '#003370' }}>{this.state.punteggioTotale}</b></Typography>
                                        )}
                                        <Typography textAlign={"center"} variant="body1">Tempo di completamento: <b style={{ color: '#003370' }}>{this.state.tempoDiCompletamento}</b></Typography>
                                    </Box>
                                    <Divider sx={{ mt: 2 }} />
                                    <PieGraph corrette={this.state.risposteCorrette} errate={this.state.risposteErrate} omesse={this.state.risposteOmesse} />
                                </Paper>

                                {/*Bottoni da dispositivi medio grandi */}
                                <Box display={{ xs: 'none', md: 'flex' }} sx={{ mt: 4 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                        {this.state.showRiepilogoDomande === false && (
                                            <Button sx={{ px: 5, mr: 2 }} variant="outlined" onClick={this.goToRiepilogo} startIcon={<ArrowForwardIcon />}>Vedi riepilogo domande</Button>
                                        )}
                                        {this.state.isGratuito === false && (
                                            <GoToImpostazioni idQuestionario={this.state.idQuestionario} idUtente={this.state.idUtente} />
                                        )}

                                    </Box>
                                </Box>

                                {/*Bottoni da dispositivi mobile */}
                                <Box display={{ xs: 'flex', md: 'none' }} sx={{ flexDirection: 'column', mt: 4 }}>
                                    {this.state.showRiepilogoDomande === true && (
                                        <Button sx={{ mt: 1, mb: 1, px: 5 }} fullWidth variant="outlined" startIcon={<ArrowBackIcon />} onClick={this.goToStatistiche}>
                                            Torna alle statistiche
                                        </Button>
                                    )}
                                    {this.state.showRiepilogoDomande === false && (
                                        <Button fullWidth sx={{ my: 1, px: 5 }} variant="outlined" onClick={this.goToRiepilogo} startIcon={<ArrowForwardIcon />}>Vedi riepilogo domande</Button>
                                    )}
                                    {this.state.isGratuito === false && (
                                        <GoToImpostazioni idQuestionario={this.state.idQuestionario} idUtente={this.state.idUtente} />
                                    )}
                                </Box>
                            </Container>
                        )
                    )}
                </Container>

                <Dialog open={this.state.showGraficoOnMobileOpen} fullWidth onClose={this.openDialogPieChart}>
                    <DialogTitle sx={{ display: 'flex', justifyContent: 'end' }}>
                        <IconButton onClick={this.openDialogPieChart}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <PieGraph corrette={this.state.risposteCorrette} errate={this.state.risposteErrate} omesse={this.state.risposteOmesse} />
                    </DialogContent>
                </Dialog>
            </Fragment>
        )
    }
}


export const GoToImpostazioni = (props) => {
    const navigate = useNavigate()

    const changePage = e => {
        e.preventDefault()
        navigate("/sim", { state: { idQuestionario: props.idQuestionario, idUtente: props.idUtente } })
    }
    const goToListaVolumi = e => {
        e.preventDefault()
        navigate("/home", { state: { volumeSelezionato: false } })
    }
    const gotListaQuestionari = e => {
        e.preventDefault()
        navigate("/home", { state: { volumeSelezionato: true } })
    }

    return (
        <Box >
            <Box display={{ xs: 'block', md: 'none' }} >
                <Button fullWidth variant="contained" onClick={changePage} startIcon={<RestartAltIcon />}>Ripeti simulatore</Button>
                <Button fullWidth variant="outlined" sx={{ mt: 3, mb: 1 }} onClick={gotListaQuestionari} >Lista simulatori</Button>
                <Button fullWidth variant="outlined" onClick={goToListaVolumi} >Lista volumi</Button>
            </Box>
            <Box display={{ xs: 'none', md: 'flex' }} >
                <Button sx={{ px: 5 }} variant="outlined" onClick={gotListaQuestionari} >Lista simulatori</Button>
                <Button sx={{ px: 5, mx: 1 }} variant="outlined" onClick={goToListaVolumi}>Lista volumi</Button>
                <Button sx={{ px: 5 }} variant="contained" onClick={changePage} startIcon={<RestartAltIcon />}>Ripeti simulatore</Button>
            </Box>
        </Box >
    )
}
