import * as React from 'react';
import { useState, useEffect, Fragment } from "react";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Slide from '@mui/material/Slide';
import Skeleton from '@mui/material/Skeleton';
import Dialog from '@mui/material/Dialog';
import { DialogTitle } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import SnackbarContent from '@mui/material/SnackbarContent';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


export default function Volume(props) {

    const [volume] = useState(props.volume)
    const [isbnVolumeRichiesto, setIsbnVolumeRichiesto] = useState(null)
    const [utente, setUtente] = useState(props.utente)
    const [cookiesAccepted, setCookiesAccepted] = useState(false)

    const [listQuestionari] = useState(props.volume.questionari)

    const [isLoaded, setIsLoaded] = useState(false)
    const [imgVolumeLoaded, setImgVolumeLoaded] = useState(true)
    const [elencoQuestionariOpen, setElencoQuestionariOpen] = useState(false)

    const navigate = useNavigate();

    useEffect(() => {
        if (localStorage.getItem("cookiesAccepted") != null) {
            setCookiesAccepted(true)
        }

        if (volume.isbn === isbnVolumeRichiesto)
            setElencoQuestionariOpen(true)
        else setElencoQuestionariOpen(false)
    }, [])

    useEffect(() => {
        if (props.isbnVolumeRichiesto != undefined) {
            if (props.isbnVolumeRichiesto == volume.isbn) {
                setElencoQuestionariOpen(true)
            }
        }
    }, [props.isbnVolumeRichiesto])

    useEffect(() => {
        if (props.utente !== utente) {
            setUtente(props.utente)
        }
    }, [props.utente])

    useEffect(() => {
        if (props.volumiLoaded !== isLoaded)
            setIsLoaded(props.volumiLoaded)
    }, [props.volumiLoaded])

    useEffect(() => {
        if (props.showVolumeRichiesto != undefined && props.volumeRichiesto != undefined) {
            if (props.volumeRichiesto == volume.isbn)
                setElencoQuestionariOpen(props.showVolumeRichiesto)
        }
    }, [props.showVolumeRichiesto])

    useEffect(() => {
        if (props.showVolumeRichiesto != undefined && props.volumeRichiesto != undefined) {
            if (props.volumeRichiesto == volume.isbn)
                setElencoQuestionariOpen(props.showVolumeRichiesto)
        }
    }, [props.volumeRichiesto])

    function openElencoQuestionari(openState) {
        if (isbnVolumeRichiesto == null)
            setElencoQuestionariOpen(openState)
    }

    function acceptCookies() {
        setCookiesAccepted(true);
        localStorage.setItem("cookiesAccepted", true);
    }

    function goToQuestionario(idQuestionario) {
        if (process.env.REACT_APP_IS_DEBUG == "true")
            navigate("/sim", { state: { idQuestionario: idQuestionario, idUtente: utente.id, isbn: volume.isbn } })
        else
            navigate("/sim", { state: { idQuestionario: idQuestionario, idUtente: utente.id, isbn: volume.isbn } })
    }


    return (
        <Fragment>
            {isbnVolumeRichiesto == null && (
                <Grid item xs={12} md={4} lg={3} xl={3}>
                    {isLoaded ? (
                        <Box sx={{ p: 5 }} className="volumeCard">
                            {imgVolumeLoaded ? (
                                <img draggable="false" alt="Copertina del volume" style={{ borderRadius: '5px', maxWidth: '75%' }} src={volume.copertina} />
                            ) : (
                                <Skeleton variant="rectangular" sx={{
                                    height: { xs: '300px', md: '350px', lg: '300px', xl: '250px' },
                                    borderRadius: '5px', width: '100%', backgroundColor: '#ffffff61'
                                }} />
                            )}
                            <Typography variant="subtitle2" sx={{ mt: 2, textAlign: 'center' }}>{volume.titolo}</Typography>
                            <Button onClick={() => openElencoQuestionari(true)} className="whiteButton" sx={{ mt: 2, display: 'flex', alignItems: 'center' }} fullWidth variant="contained" endIcon={<FormatListBulletedIcon />}>
                                Vai ai simulatori
                            </Button>
                            <Box className='backgroundVolumeCard' sx={{ backgroundImage: `url(` + volume.copertina + `)` }}>
                            </Box>
                        </Box>
                    ) : (
                        <Skeleton variant="rectangular" sx={{ height: { xs: '350px', md: '400px', lg: '500px', xl: '400px' } }} />
                    )}
                </Grid>
            )}

            <Dialog fullWidth maxWidth="xl" open={elencoQuestionariOpen} TransitionComponent={Transition} onClose={() => openElencoQuestionari(false)}>

                {/* {isbnVolumeRichiesto == null && ( */}
                <DialogTitle sx={{ display: 'flex', justifyContent: 'end' }}>
                    <IconButton onClick={() => openElencoQuestionari(false)}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                {/* )} */}
                <Box sx={{ p: 5, mb: 3 }} className="volumeSelezionatoCard">
                    <Grid container spacing={2} style={{ zIndex: '1' }} >
                        <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Box sx={{ width: '100%', display: { xs: 'none', md: 'flex' }, alignItems: 'center', justifyContent: 'center' }}>
                                {imgVolumeLoaded ? (
                                    <img draggable="false" alt="Copertina del volume" className="copertina-volume-dialog" src={volume?.copertina} />
                                ) : (
                                    <Skeleton variant="rectangular" sx={{ height: { xs: '200px', md: '300px', lg: '400px', xl: '300px', borderRadius: '5px', maxWidth: '45%' } }} />
                                )}
                            </Box>
                            <Box sx={{ display: { xs: 'flex', md: 'none' }, alignItems: 'center', justifyContent: 'center' }}>
                                <img draggable="false" alt="Copertina del volume" style={{ borderRadius: '5px', maxWidth: '100%' }} src={volume.copertina} />
                            </Box>

                        </Grid>
                        <Grid item xs={8} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'start' }}>
                            <Box sx={{ display: { xs: 'none', md: 'block' } }} >
                                <Typography variant="h3" sx={{ backgroundColor: '#000000ab', px: 2 }}>{volume?.titolo} </Typography>
                                {/* {volume.sottotitolo != undefined && volume.sottotitolo != null && (
                                <Typography variant="h6" sx={{ backgroundColor: '#000000ab', px: 2 }}>{volume.sottotitolo} </Typography>
                                )} */}
                            </Box>
                            <Box sx={{ display: { xs: 'block', md: 'none' } }} >
                                <Typography variant="body2" sx={{ backgroundColor: '#000000ab', px: 2 }}>{volume.titolo} </Typography>
                                {/* {volume.sottotitolo != undefined && volume.sottotitolo != null && (
                                    <Typography variant="subtitle2" sx={{ backgroundColor: '#000000ab', px: 2 }}>{volume.sottotitolo} </Typography>
                                )} */}
                            </Box>
                        </Grid>
                    </Grid>
                    <Box className='backgroundVolumeSelezionatoCard' sx={{ backgroundImage: `url(` + volume.copertina + `)` }}>
                    </Box>
                </Box>
                <Box sx={{ px: { xs: 2, md: 5 }, pb: 5, overflowY: 'auto' }}>
                    {/* <Typography variant="h3" sx={{ mb: 2 }}>Elenco questionari</Typography> */}
                    <Grid container spacing={3} alignItems="stretch">
                        {listQuestionari.length > 0 ? (
                            listQuestionari.map((questionario, index) => (
                                <Fragment key={index}>
                                    {questionario.id != null && (
                                        <Grid item md={6} xs={12}>
                                            <Paper sx={{ height: { xs: 'fit-content', md: '100%' }, p: 2, display: 'flex', alignItems: 'center' }} elevation={5}>
                                                <Grid container spacing={3} sx={{ height: '100%' }}>
                                                    <Grid item xs={12} md={8} sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                                                        <ArrowForwardIcon sx={{ mr: 2 }} color='success' />
                                                        <Typography variant="subtitle1" >{questionario.nome}</Typography>
                                                    </Grid>
                                                    <Grid item xs={12} md={4} sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                                                        <Button fullWidth variant="contained" color="success" sx={{ color: 'white' }} onClick={() => goToQuestionario(questionario.id)}>Vai al simulatore</Button>
                                                    </Grid>
                                                </Grid>
                                            </Paper>
                                        </Grid>
                                    )}
                                </Fragment>
                            ))
                        ) : (
                            <Grid item xs={12} sx={{ mt: 2, textAlign: 'center' }}>
                                <Typography variant="h5">Nessun simulatore associato al volume.</Typography>
                            </Grid>
                        )}
                    </Grid>
                </Box>

            </Dialog>


        </Fragment>
    )
}