import React, { useState, useEffect, Fragment, useRef } from 'react';
import { styled } from '@mui/material/styles';
import { StatisticheQuizSvolto } from './statisticheQuizSvolto';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { grey } from '@mui/material/colors';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Chip, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close'; import TextField from '@mui/material/TextField';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CircularProgress, {
    circularProgressClasses,
} from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useLocation } from "react-router-dom"
import { Pagination } from '../components/vistaQuiz/pagination';
import { Answer } from '../components/vistaQuiz/answer';
import { Timer } from '../components/vistaQuiz/timer';
import Logo from '../logoSimone.png'
import ReportProblemIcon from '@mui/icons-material/ReportProblem';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Error, ErrorOutline, PriorityHigh } from '@mui/icons-material';

export default function VistaQuiz(props) {

    const [nomeQuestionario, setNomeQuestionario] = useState('Quiz');
    const [idUtente, setIdUtente] = useState(99);

    const [dataInizio, setDataInizio] = useState(0); //Formato timestamp
    const [dataFine, setDataFine] = useState(0)

    const [quizLoaded, setQuizLoaded] = useState(false);

    const [selectedRisposte, setSelectedRisposte] = useState([]);

    const [indexDomanda, setIndexDomanda] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [indexDomandaProgress, setIndexDomandaProgress] = useState(0);
    const [indexSezione, setIndexSezione] = useState(0);
    const [settingsDialogOpen, setSettingsDialogOpen] = useState(false);
    const [showCorrectAnswer, setShowCorrectAnswer] = useState(false);
    const [nextQuestion, setNextQuestion] = useState(false);
    const [statisticheDialogOpen, setStatisticheDialogOpen] = useState(false);
    const [sezioni, setSezioni] = useState([]);
    const [questionarioCompilato, setQuestionarioCompilato] = useState([]);

    const [timer, setTimer] = useState(3600);
    const [tempoIniziale, setTempoIniziale] = useState(0);
    const [tempoCompletamento, setTempoCompletamento] = useState(0);
    const [remainingTime, setRemainingTime] = useState(3600);
    const [lastSectionNumber, setLastSectionNumber] = useState(1);

    const [tempoTrascorsoInSezione, setTempoTrascorsoInSezione] = useState(0);
    const [sezioniConTempoMinTrascorso, setSezioniConTempoMinTrascorso] = useState([]);

    const [totalNumberQuiz, setTotalNumberQuiz] = useState(0);
    const [questionarioSelezionatoID, setQuestionarioSelezionatoID] = useState(-1);
    const [volumeSelezionatoID, setvolumeSelezionatoID] = useState(-1);
    const [typeQuestionario, setTypeQuestionario] = useState('Sim');
    const [isGratuito, setIsGratuito] = useState(false);
    const [errorQuiz, setErrorQuiz] = useState(false);
    const [quizSection, setquizSection] = useState([]);
    const [idQuizCompletati, setIdQuizCompletati] = useState([]);
    const [quizPartenza] = useState(0);

    const [testoSegnalazione, setTestoSegnalazione] = useState('');
    const [dialogSegnalazioneOpen, setDialogSegnalazioneOpen] = useState(false);
    const [alertSegnalazioneInviataOpen, setAlertSegnalazioneInviataOpen] = useState(false);
    const [tipoAlertSegnalazione, setTipoAlertSegnalazione] = useState('success');

    const [loadingInvioSegnalazione, setLoadingInviaSegnalazione] = useState(false);

    const [nextDomandaButtonDisabled, setNextDomandaButtonDisabled] = useState(false);

    const [pageNumberLimit, setPageNumberLimit] = useState(0);
    const [maxPage, setMaxPage] = useState(0);
    const [minPage, setMinPage] = useState(0);
    const [windowWidth, setWindiwWidth] = useState(window.innerWidth)
    const [openEndQuizDialog, setOpenEndQuizDialog] = useState(false);

    const [timeFinishedDialogOpen, setTimeFinishedDialogOpen] = useState(false)
    const [urlImageToZoom, setUrlImageToZoom] = useState("false")
    const [openDialogImageZoom, setOpenDialogImageZoom] = useState(false)

    const [sogliaSufficienza, setSogliaSufficienza] = useState(0);
    const navigate = useNavigate();
    const location = useLocation();
    const abortController = new AbortController();
    const signal = abortController.signal;

    const titlePcRef = useRef(null)
    const titleMobileRef = useRef(null)

    useEffect(() => {

        setDataInizio(new Date().getTime());

        document.addEventListener("click", function (event) {
            let targetElement = event.target;
            if (targetElement.tagName === 'IMG') {
                let selectable = event.target?.attributes?.unselectable?.value ?? undefined;
                if (selectable === undefined) {
                    setUrlImageToZoom(targetElement.src);
                    setOpenDialogImageZoom(true);
                }
            }
        });

        if (location.state != null) {
            setIdUtente(location.state.idUtente)
            setIsGratuito(location.state.gratuito)
            if (location.state.prettyLink !== "") {
                let currentUrl = window.location.href;
                let permalink = currentUrl.split("quiz/")[1];
                window.history.pushState({}, 'Questionario', process.env.REACT_APP_PUBLIC_URL + process.env.REACT_APP_PREFIX + "/questionario/" + permalink);
            }
            if (location.state.questionario != null) {
                setvolumeSelezionatoID(location.state.questionario.idVolume)
                setNomeQuestionario(location.state.questionario.nome)
                setTypeQuestionario(location.state.type)
                setSogliaSufficienza(location.state.questionario.sogliaSufficienza)
                if (location.state.type === "Ese") {
                    setTotalNumberQuiz(location.state.numeroQuiz)
                }

                // if (location.state.questionario.permalink != null && location.state.questionario.permalink != "") {
                //     let url = location.state.questionario.permalink;
                //     window.history.replaceState(null, location.state.questionario.nome, url)
                // } else {
                //     let url = location.state.questionario.nome;
                //     window.history.replaceState(null, location.state.questionario.nome, url)
                // }
            }

            if (location.state.gratuito === false || location.state.gratuito === undefined || location.state.gratuito == null)
                parseQuestionario();
            else parseQuestionarioGratuito();
        }
        else { //Se location.state è null allora ti rimando alla home
            navigate("/")
        }
        return () => {
            abortController.abort();
        }
    }, []);

    useEffect(() => {
        detectWindowWidth();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [windowWidth]);

    // useEffect(()=>{
    //     if(props.sogliaSufficienza !== undefined)
    //     setSogliaSufficienza(props.sogliaSufficienza)
    // },[props.sogliaSufficienza])


    useInterval(() => {
        if (sezioni.length > 0) {
            setTempoTrascorsoInSezione(tempoTrascorsoInSezione + 1);
        }
    }, 1000);


    //Metodi server
    function parseQuestionario() {

        let partiListTmp = [];
        let data;
        let timerTmp = 0;
        // if (location.state.type !== 'Seq') {

        if (location.state.type !== 'Ese') {
            for (let i = 0; i < location.state.parti.length; i++) {
                if (location.state.parti[i].quizSelezionati > 0)
                    timerTmp += location.state.parti[i].tempMax * 60;
            }
        } else { //In esercitazione il tempo viene calcolato 60sec * nQuiz
            for (let i = 0; i < location.state.parti.length; i++) {
                if (location.state.parti[i].quizSelezionati > 0)
                    timerTmp += location.state.parti[i].quizSelezionati * 60;
            }
        }
        setTimer(timerTmp)

        if (location.state.parti.length > 0) {
            if (location.state.parti[0].tempMax > 0)
                //setTimer(location.state.parti[0].tempMax * 60)

                for (let parte of location.state.parti) {
                    let parteTmp;
                    if (location.state.type === 'Sim') {
                        parteTmp = {
                            idParte: parte.idParte,
                            numQuiz: parte.quizDisponibili,
                        }
                    }
                    else if (location.state.type === 'Ese') {
                        parteTmp = {
                            idParte: parte.idParte,
                            numQuiz: parte.quizSelezionati,
                        }
                    }
                    else if (location.state.type === 'Seq') {
                        parteTmp = {
                            idParte: parte.idParte,
                            numQuiz: parte.quizSelezionati,
                            offset: parte.offset,
                            idVolume: parte.idVolume
                        }
                    }


                    if (location.state.type !== 'Seq') {
                        parteTmp.tempMin = parseInt(parte.tempMin);
                        parteTmp.tempMax = parseInt(parte.tempMax);
                        parteTmp.remainingTime = parseInt(parte.tempMax);
                    }

                    if (parteTmp.numQuiz > 0) { //Se la parte non ha quiz disponibili, la escludo
                        partiListTmp.push(parteTmp);
                    }
                }

        }

        if (location.state.type === 'Ese') {
            data = {
                "type": location.state.type,
                "id": location.state.idQuestionario,
                "parti": partiListTmp
            }
            if (location.state.riprovaSbagliati !== undefined)
                data.riprovaSbagliati = location.state.riprovaSbagliati
        }
        else if (location.state.type === 'Sim') {
            data = {
                "type": location.state.type,
                "id": location.state.idQuestionario,
            }
            if (location.state.riprovaSbagliati !== undefined)
                data.riprovaSbagliati = location.state.riprovaSbagliati
        }
        else if (location.state.type === 'Seq') {
            data = {
                "type": location.state.type,
                "id": location.state.idQuestionario,
                "parti": partiListTmp
            }
        }
        // }
        // else {
        //     //offset -1 perchè parte da 1 e non da 0
        //     data = {
        //         "type": location.state.type,
        //         "id": location.state.idQuestionario,
        //         "offset": location.state.offset - 1,
        //         "numQuiz": location.state.quizSequenzialiSelezionati
        //     }
        //     setQuizPartenza(0)//(location.state.offset - 1)
        // }



        let requestOptions = {
            signal: signal,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        };

        fetch(process.env.REACT_APP_FETCH_URL + 'questionario/parse', requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.success) {
                    let questionCompilatoArray = [];
                    let tmpSezioni = []; //Per evitare che vengano messe sezioni senza quiz
                    let numberQuiz = 0;
                    for (let i = 0; i < result.data.sezione.length; i++) {
                        if (result.data.sezione[i].quiz.length > 0) {
                            let tmpSezione = result.data.sezione[i]
                            tmpSezione.tempMin = location.state.parti[i].tempMin;
                            tmpSezione.tempMax = location.state.parti[i].tempMax;
                            tmpSezione.puntiOmessa = location.state.parti[i].puntiOmessa;
                            tmpSezione.puntiCorretta = location.state.parti[i].puntiCorretta;
                            tmpSezione.puntiErrata = location.state.parti[i].puntiErrata;
                            let titleTmp = '';
                            for (let j = 0; j < tmpSezione.quiz.length; j++) {

                                //pulizia domanda
                                titleTmp = tmpSezione.quiz[j].title;
                                titleTmp = unEscape(titleTmp);
                                if (titleTmp.includes("src=\"http://sandbox2.itlogix.it/")) {
                                    titleTmp = titleTmp.replaceAll("src=\"http://sandbox2.itlogix.it/", "src=\"" + process.env.REACT_APP_IMAGES_PREFIX)
                                }
                                else if (titleTmp.includes("src=\"\/")) { // /static/uploads/quizImg/quizImg_1653561997856.jpg
                                    titleTmp = titleTmp.replaceAll("src=\"\/", "src=\"" + process.env.REACT_APP_IMAGES_PREFIX)
                                } // static/uploads/quizImg/quizImg_1653561997856.jpg (Se static non ha il carattere / davanti)
                                else titleTmp = titleTmp.replaceAll("src=\"", "src=\"" + process.env.REACT_APP_IMAGES_PREFIX)

                                if (titleTmp.includes("<table>")) {
                                    titleTmp = titleTmp.replaceAll("<table>", '<table style="border-collapse: collapse; width:100 %;" border="1">')
                                }
                                if (titleTmp.includes("<td></td>"))
                                    titleTmp = titleTmp.replaceAll("<td></td>", '')

                                tmpSezione.quiz[j].title = titleTmp;

                                //pulizia risposte
                                for (let x = 0; x < tmpSezione.quiz[j].choices.length; x++) {
                                    let tmpChoice = tmpSezione.quiz[j].choices[x].text;
                                    tmpChoice = unEscape(tmpChoice);
                                    if (tmpChoice.includes("src=\"http://sandbox2.itlogix.it/")) {
                                        tmpChoice = tmpChoice.replaceAll("src=\"http://sandbox2.itlogix.it/", "src=\"" + process.env.REACT_APP_IMAGES_PREFIX)
                                    }
                                    else if (tmpChoice.includes("src=\"\/")) { // /static/uploads/quizImg/quizImg_1653561997856.jpg
                                        tmpChoice = tmpChoice.replaceAll("src=\"\/", "class=\"zoomImage\"src=\"" + process.env.REACT_APP_IMAGES_PREFIX)
                                    } // static/uploads/quizImg/quizImg_1653561997856.jpg (Se static non ha il carattere / davanti)
                                    else tmpChoice = tmpChoice.replaceAll("src=\"", "class=\"zoomImage\" src=\"" + process.env.REACT_APP_IMAGES_PREFIX)
                                    tmpSezione.quiz[j].choices[x].text = tmpChoice;
                                }

                                questionCompilatoArray.push({
                                    "quizId": tmpSezione.quiz[j].quizId,
                                    "type": tmpSezione.quiz[j].type,
                                    "title": tmpSezione.quiz[j].title,
                                    "choices": tmpSezione.quiz[j].choices,
                                    "choicesOrder": tmpSezione.quiz[j].choicesOrder,
                                    "correctAnswer": tmpSezione.quiz[j].correctAnswer,
                                    "givenAnswer": selectedRisposte.length > 0 ? selectedRisposte : [],
                                    "puntiErrata": tmpSezione.puntiErrata !== undefined ? tmpSezione.puntiErrata : -1,
                                    "puntiOmessa": tmpSezione.puntiOmessa !== undefined ? tmpSezione.puntiOmessa : -0,
                                    "puntiCorretta": tmpSezione.puntiCorretta !== undefined ? tmpSezione.puntiCorretta : 1,
                                })


                            }
                            numberQuiz += tmpSezione.quiz.length;
                            setQuestionarioCompilato(questionCompilatoArray)
                            tmpSezioni.push(tmpSezione)
                        }
                    }
                    if (numberQuiz > 0) {
                        setSezioni(tmpSezioni)
                        getTotalAnswerNumber(result.data.sezione)
                        setQuestionarioSelezionatoID(location.state.idQuestionario);
                        setvolumeSelezionatoID(location.state.idVolume);
                        setSogliaSufficienza(location.state.questionario.sogliaSufficienza)

                        let typeQuestionarioTmp = location.state?.type ?? "Sim";
                        if (typeQuestionarioTmp === "Ese") {
                            setTempoIniziale(tmpSezioni[0].quiz.length);
                        } else {
                            if (tmpSezioni[0].tempMax != undefined) {
                                setTempoIniziale(tmpSezioni[0].tempMax);
                            }
                            else {
                                setTempoIniziale(60);
                            }
                        }
                        setErrorQuiz(false);
                        setQuizLoaded(true);
                        setLastSectionNumber(tmpSezioni[0].quiz.length)

                        // if (typeQuestionario !== 'Sim')
                        //                        setSogliaSufficienza(result.data.sogliaSufficienza)
                    } else {
                        setQuizLoaded(true);
                        setErrorQuiz(true);
                    }

                }
                else {
                    setErrorQuiz(true);
                }
            })
    }
    function parseQuestionarioGratuito() {

        let partiListTmp = [];
        let data;
        let timerTmp = 0;
        // if (location.state.type !== 'Seq') {

        for (let i = 0; i < location.state.parti.length; i++) {
            if (location.state.parti[i].quizSelezionati > 0)
                timerTmp += location.state.parti[i].tempMax * 60;
        }
        setTimer(timerTmp)

        if (location.state.parti.length > 0) {
            if (location.state.parti[0].tempMax > 0)
                //setTimer(location.state.parti[0].tempMax * 60)

                for (let parte of location.state.parti) {
                    let parteTmp;
                    if (location.state.type === 'Sim') {
                        parteTmp = {
                            idParte: parte.idParte,
                            numQuiz: parte.quizDisponibili,
                        }
                    }
                    else if (location.state.type === 'Ese') {
                        parteTmp = {
                            idParte: parte.idParte,
                            numQuiz: parte.quizSelezionati,
                        }
                    }
                    else if (location.state.type === 'Seq') {
                        parteTmp = {
                            idParte: parte.idParte,
                            numQuiz: parte.quizSelezionati,
                            offset: parte.offset,
                            idVolume: parte.idVolume
                        }
                    }


                    if (location.state.type !== 'Seq') {
                        parteTmp.tempMin = parseInt(parte.tempMin);
                        parteTmp.tempMax = parseInt(parte.tempMax);
                        parteTmp.remainingTime = parseInt(parte.tempMax);
                    }

                    if (parteTmp.numQuiz > 0) { //Se la parte non ha quiz disponibili, la escludo
                        partiListTmp.push(parteTmp);
                    }
                }

        }

        if (location.state.type === 'Ese') {
            data = {
                "type": location.state.type,
                "id": location.state.idQuestionario,
                "parti": partiListTmp
            }
        }
        else if (location.state.type === 'Sim') {
            data = {
                "type": location.state.type,
                "id": location.state.idQuestionario,
            }
        }
        else if (location.state.type === 'Seq') {
            data = {
                "type": location.state.type,
                "id": location.state.idQuestionario,
                "parti": partiListTmp
            }
        }

        let requestOptions = {
            signal: signal,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        };

        fetch(process.env.REACT_APP_FETCH_URL + 'questionario/parseGratuito', requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.success) {
                    let questionCompilatoArray = [];
                    let tmpSezioni = []; //Per evitare che vengano messe sezioni senza quiz
                    let numberQuiz = 0;
                    for (let i = 0; i < result.data.sezione.length; i++) {
                        if (result.data.sezione[i].quiz.length > 0) {
                            let tmpSezione = result.data.sezione[i]
                            tmpSezione.tempMin = location.state.parti[i].tempMin;
                            tmpSezione.tempMax = location.state.parti[i].tempMax;
                            tmpSezione.puntiOmessa = location.state.parti[i].puntiOmessa;
                            tmpSezione.puntiCorretta = location.state.parti[i].puntiCorretta;
                            tmpSezione.puntiErrata = location.state.parti[i].puntiErrata;

                            let titleTmp = '';
                            for (let j = 0; j < tmpSezione.quiz.length; j++) {

                                //pulizia domanda
                                titleTmp = tmpSezione.quiz[j].title;
                                titleTmp = unEscape(titleTmp);
                                if (titleTmp.includes("src=\"http://sandbox2.itlogix.it/")) {
                                    titleTmp = titleTmp.replaceAll("src=\"http://sandbox2.itlogix.it/", "src=\"" + process.env.REACT_APP_IMAGES_PREFIX)
                                }
                                else if (titleTmp.includes("src=\"\/")) { // /static/uploads/quizImg/quizImg_1653561997856.jpg
                                    titleTmp = titleTmp.replaceAll("src=\"\/", "class=\"zoomImage\" src=\"" + process.env.REACT_APP_IMAGES_PREFIX)
                                } // static/uploads/quizImg/quizImg_1653561997856.jpg (Se static non ha il carattere / davanti)
                                else titleTmp = titleTmp.replaceAll("src=\"", "class=\"zoomImage\" src=\"" + process.env.REACT_APP_IMAGES_PREFIX)

                                if (titleTmp.includes("<table>")) {
                                    titleTmp = titleTmp.replaceAll("<table>", '<table style="border-collapse: collapse; width:100 %;" border="1">')
                                }
                                if (titleTmp.includes("<td></td>"))
                                    titleTmp = titleTmp.replaceAll("<td></td>", '')

                                tmpSezione.quiz[j].title = titleTmp;

                                //pulizia risposte
                                for (let x = 0; x < tmpSezione.quiz[j].choices.length; x++) {
                                    let tmpChoice = tmpSezione.quiz[j].choices[x].text;
                                    tmpChoice = unEscape(tmpChoice);
                                    if (tmpChoice.includes("src=\"http://sandbox2.itlogix.it/")) {
                                        tmpChoice = tmpChoice.replaceAll("src=\"http://sandbox2.itlogix.it/", "src=\"" + process.env.REACT_APP_IMAGES_PREFIX)
                                    }
                                    else if (tmpChoice.includes("src=\"\/")) { // /static/uploads/quizImg/quizImg_1653561997856.jpg
                                        tmpChoice = tmpChoice.replaceAll("src=\"\/", "src=\"" + process.env.REACT_APP_IMAGES_PREFIX)
                                    } // static/uploads/quizImg/quizImg_1653561997856.jpg (Se static non ha il carattere / davanti)
                                    else tmpChoice = tmpChoice.replaceAll("src=\"", "src=\"" + process.env.REACT_APP_IMAGES_PREFIX)
                                    tmpSezione.quiz[j].choices[x].text = tmpChoice;
                                }

                                questionCompilatoArray.push({
                                    "quizId": tmpSezione.quiz[j].quizId,
                                    "type": tmpSezione.quiz[j].type,
                                    "title": tmpSezione.quiz[j].title,
                                    "choices": tmpSezione.quiz[j].choices,
                                    "choicesOrder": tmpSezione.quiz[j].choicesOrder,
                                    "correctAnswer": tmpSezione.quiz[j].correctAnswer,
                                    "givenAnswer": selectedRisposte.length > 0 ? selectedRisposte : [],
                                    "puntiErrata": tmpSezione.puntiErrata !== undefined ? tmpSezione.puntiErrata : -1,
                                    "puntiOmessa": tmpSezione.puntiOmessa !== undefined ? tmpSezione.puntiOmessa : -0,
                                    "puntiCorretta": tmpSezione.puntiCorretta !== undefined ? tmpSezione.puntiCorretta : 1,
                                })


                            }
                            numberQuiz += tmpSezione.quiz.length;
                            setQuestionarioCompilato(questionCompilatoArray)
                            tmpSezioni.push(tmpSezione)
                        }
                    }
                    if (numberQuiz > 0) {
                        setSezioni(tmpSezioni)
                        getTotalAnswerNumber(result.data.sezione)
                        setQuestionarioSelezionatoID(location.state.idQuestionario);
                        setvolumeSelezionatoID(location.state.idVolume);
                        setSogliaSufficienza(result?.data?.sogliaSufficienza ?? 0);
                        // setSogliaSufficienza(location.state.questionario.sogliaSufficienza)

                        if (tmpSezioni[0].tempMax != undefined) {
                            setTempoIniziale(tmpSezioni[0].tempMax);
                        }
                        else {
                            setTempoIniziale(60);
                        }
                        setErrorQuiz(false);
                        setQuizLoaded(true);
                        setLastSectionNumber(tmpSezioni[0].quiz.length)

                        // if (typeQuestionario !== 'Sim')
                        // setSogliaSufficienza(result.data.sogliaSufficienza)
                    } else {
                        setQuizLoaded(true);
                        setErrorQuiz(true);
                    }

                }
                else {
                    setErrorQuiz(true);
                }
            })
    }



    function calculateTempoRestanteSezione() {
        let tempoRestante;
        tempoRestante = (sezioni[indexSezione].tempMin * 60) - tempoTrascorsoInSezione;
        if (tempoRestante > 0) return convertNumberToMinutes(tempoRestante)
        else return 0;
    }

    //Metodi Utility
    function convertMinutesToSeconds(time) {  /*Converto i secondi in formato MM:SS */
        let minutes = Math.floor(time / 60);
        let seconds = time - minutes * 60;
        if (minutes < 10) minutes = '0' + minutes
        if (seconds < 10) seconds = '0' + seconds
        return (minutes + "." + seconds)
    }
    function convertNumberToMinutes(number) {
        let minutes = Math.floor(number / 60);
        var seconds = number - minutes * 60;
        return minutes + ":" + seconds
    }
    function progressBarPercentage() { /*Barra orizzontale domanda attuale/domande totali */
        if (quizLoaded === true) {
            let current = indexDomandaProgress;
            return Math.round((current * 100) / totalNumberQuiz)
        }
    }
    function getTotalAnswerNumber(sezioniTmp) {

        let quizTotalNumber = 0;
        for (let i = 0; i < sezioniTmp.length; i++) {
            quizTotalNumber += sezioniTmp[i].quiz.length;
        }
        setTotalNumberQuiz(quizTotalNumber);

    }
    function unEscape(htmlStr) {

        if (htmlStr.match(/&lt;/g))
            htmlStr = htmlStr.replace(/&lt;/g, "<");

        if (htmlStr.match(/&gt;/g))
            htmlStr = htmlStr.replace(/&gt;/g, ">");

        if (htmlStr.match(/&quot;/g))
            htmlStr = htmlStr.replace(/&quot;/g, "\"");

        if (htmlStr.match(/&#39;/g))    //eslint-disable-next-line  no-useless-escape
            htmlStr = htmlStr.replace(/&#39;/g, "\'");

        if (htmlStr.match(/&amp;/g))
            htmlStr = htmlStr.replace(/&amp;/g, "&");

        /*if (htmlStr.includes("<p")) {
            let length = htmlStr.split("<p").length - 1 + htmlStr.split("/p>").length
            for (let i = 0; i < length; i++) {
                htmlStr = htmlStr.replace(/(<p[^>]+?>|<p>|<\/p>)/, "");
            }
        }

        if (htmlStr.includes("<span")) {
            let length = htmlStr.split("<span").length - 1 + htmlStr.split("/span>").length
            for (let i = 0; i < length; i++) {
                htmlStr = htmlStr.replace(/(<span[^>]+?>|<span>|<\/span>)/, "");
            }
        } 
        */

        return htmlStr;


    }
    function checkForEmptyGivenAnswer() {
        if (!showCorrectAnswer || (showCorrectAnswer && nextQuestion)) {
            nextQuestionCheck(true);
            //checkQuizInSection();
            let userAsnwers = questionarioCompilato.filter(quiz => quiz.givenAnswer.length === 0)
            if (userAsnwers.length > 0) {
                handleDialogOpen();
            } else {
                nextQuestionCheck();
                checkQuizInSection();
            }
        } else {
            setNextQuestion(true);
        }
    }
    function checkQuizInSection() {
        let quizSectionArray = quizSection;
        let checkedId = indexDomanda;
        if (!quizSectionArray.includes(checkedId)) {
            quizSectionArray.push(checkedId);
            setquizSection(quizSectionArray)
        }

    }
    function changeRemainingTime(remainingTime) {
        let remainingTimeTmp = remainingTime;
        if (sezioni[indexSezione].tempMin > 0) {
            remainingTimeTmp = remainingTimeTmp - (sezioni[indexSezione].tempMax * 60)
            setRemainingTime(remainingTimeTmp)
        } else setRemainingTime(0);
    }
    function onTimeFinished() {
        setTimeFinishedDialogOpen(true)
    }
    function changePage(e) {
        e.preventDefault()
        navigate("/sim", { state: { idQuestionario: questionarioSelezionatoID, idUtente: idUtente } })
        //navigate("/")
    }
    function goBackToQuestionarioGratuito() {
        navigate("/sim/" + location.state.prettyLink)
    }
    async function sendSegnalazione() {
        if (testoSegnalazione !== "") {
            let risoluzioneBrowser = window.innerWidth + 'x' + window.innerHeight;

            let quizHtml = "";
            quizHtml += "<p><b>Domanda: </b><br>" + sezioni[indexSezione].quiz[indexDomanda].title + "</p>";
            quizHtml += "<div>";
            for (let i = 0; i < sezioni[indexSezione].quiz[indexDomanda].choices.length; i++) {
                quizHtml += "<b>Risposta n." + (i + 1) + ": </b><br>"
                quizHtml += sezioni[indexSezione].quiz[indexDomanda].choices[i].text + "<br>"
            }
            quizHtml += "</div>";

            let emailBody = testoSegnalazione + "<br>" + quizHtml;

            let data = {
                emailBody: emailBody,
                risoluzione: risoluzioneBrowser,
                idQuestionario: questionarioSelezionatoID,
                idQuiz: sezioni[indexSezione].quiz[indexDomanda].quizId,
                userAgent: navigator.userAgent
            }

            setLoadingInviaSegnalazione(true);

            let url = process.env.REACT_APP_FETCH_URL + "questionario/userFeedback";
            let requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data),
                signal: signal
            };

            try {
                let res = await fetch(url, requestOptions);
                if (res.status === 200) {
                    res.json().then(
                        result => {
                            setLoadingInviaSegnalazione(false);

                            if (result.success) {
                                setDialogSegnalazioneOpen(false);
                                setTestoSegnalazione('');
                                setAlertSegnalazioneInviataOpen(true)
                                setTipoAlertSegnalazione('success')
                            }
                            else {
                                setAlertSegnalazioneInviataOpen(true)
                                setTipoAlertSegnalazione('error')
                            }
                        }
                    )
                }
            } catch (error) {
                setLoadingInviaSegnalazione(false);
            }
        }
    }

    //Metodi setter
    function selectRisposta(domanda, index) {
        if (domanda.value !== undefined) {
            let selectedRisposteTmp = selectedRisposte;
            // if (sezioni[indexSezione].quiz[indexDomanda].correctAnswer.length > 1) { //Se ha piu di una risposta corretta
            //     if (!selectedRisposte.includes(domanda.value)) {
            //         selectedRisposteTmp.push(domanda.value)
            //     }
            //     else {
            //         let index = selectedRisposteTmp.indexOf(domanda.value);
            //         selectedRisposteTmp.splice(index, 1);
            //     }
            //     setSelectedRisposte([...selectedRisposteTmp]);
            //     // if (domanda.value != selectedDomanda)
            //     //     setSelectedDomanda(domanda.value)
            //     // else setSelectedDomanda("");
            // } else {
            //     if (!selectedRisposte.includes(domanda.value)) {
            //         selectedRisposteTmp = [domanda.value]
            //     }
            //     else {
            //         selectedRisposteTmp = []
            //     }
            //     setSelectedRisposte([...selectedRisposteTmp]);
            // }


            if (!selectedRisposte.includes(domanda.value)) {
                selectedRisposteTmp = [domanda.value]
            }
            else {
                selectedRisposteTmp = []
            }
            setSelectedRisposte([...selectedRisposteTmp]);
        }
        else {
            if (index != selectedDomanda) {
                let selectedRisposteTmp = selectedRisposte;

                if (!selectedRisposte.includes(index)) {
                    selectedRisposteTmp.push(index)
                }
                else {
                    let indexTmp = selectedRisposteTmp.indexOf(index);
                    selectedRisposteTmp.splice(indexTmp, 1);
                }
                setSelectedRisposte([...selectedRisposteTmp]);

            } else setSelectedRisposte([]);
            //         setSelectedDomanda(index);
            //     else setSelectedDomanda("");
        }
    }
    function setCorrectAnswer() {
        setShowCorrectAnswer(!showCorrectAnswer);
    }
    function changeTextSegnalazione(e) {
        setTestoSegnalazione(e.target.value)
    }

    //Metodi dialog
    function closeSettingsDialog() {
        setSettingsDialogOpen(false);
    }
    function handleDialogOpen() {
        setOpenEndQuizDialog(true);
    };
    function handleDialogClose() {
        setOpenEndQuizDialog(false);
    };
    function openDialogSegnalazione() {
        let openTmp = !dialogSegnalazioneOpen;
        setDialogSegnalazioneOpen(openTmp)
    }
    function closeAlertSegnalazione() {
        setAlertSegnalazioneInviataOpen(false)
    }

    //Metodi bottoni
    function nextQuestionCheck(skipCheck) { /*Controlla se è abilitata l'impostazione per controllare la risposta corretta o meno */
        if (showCorrectAnswer === false || currentPage === questionarioCompilato.length - 1) {
            nextQuestionMethod(skipCheck);
        } else setNextQuestion(true);

        if (showCorrectAnswer === true && nextQuestion === true && currentPage !== questionarioCompilato.length - 1) {
            {
                setNextDomandaButtonDisabled(true);
                nextQuestionMethod();
            }
        }
    }

    function nextQuestionMethod(skipCheck) {

        let idQuizArray = idQuizCompletati;
        let questionarioCompilatoTmp = questionarioCompilato; //Aggiungo la risposta data all'array che poi salverò nelle statistiche
        let checkPaginationItem = idQuizArray.filter(quiz => quiz === sezioni[indexSezione].quiz[indexDomanda].quizId)
        idQuizArray.push(sezioni[indexSezione].quiz[indexDomanda].quizId)
        setIdQuizCompletati(idQuizArray)
        let indexofQuestion = questionarioCompilatoTmp.findIndex(quiz => quiz.quizId === sezioni[indexSezione].quiz[indexDomanda].quizId)

        questionarioCompilatoTmp[indexofQuestion] = {
            ...questionarioCompilatoTmp[indexofQuestion],
            "quizId": sezioni[indexSezione].quiz[indexDomanda].quizId,
            "type": sezioni[indexSezione].quiz[indexDomanda].type,
            "title": sezioni[indexSezione].quiz[indexDomanda].title,
            "choices": sezioni[indexSezione].quiz[indexDomanda].choices,
            "choicesOrder": sezioni[indexSezione].quiz[indexDomanda].choicesOrder,
            "correctAnswer": sezioni[indexSezione].quiz[indexDomanda].correctAnswer,
            "givenAnswer": selectedRisposte.length > 0 ? selectedRisposte : [],
            "puntiErrata": sezioni[indexSezione].puntiErrata !== undefined ? sezioni[indexSezione].puntiErrata : -1,
            "puntiOmessa": sezioni[indexSezione].puntiOmessa !== undefined ? sezioni[indexSezione].puntiOmessa : -0,
            "puntiCorretta": sezioni[indexSezione].puntiCorretta !== undefined ? sezioni[indexSezione].puntiCorretta : 1,
        }
        setQuestionarioCompilato(questionarioCompilatoTmp);

        if (indexDomanda + 1 < sezioni[indexSezione].quiz.length) { //Se non è l'ultima domanda vai alla successiva
            let newIndex = indexDomanda;
            newIndex++;
            if (checkPaginationItem.length === 0) {
                let newIndexProgress = indexDomandaProgress;
                newIndexProgress++;
                //Se NON è abilitata l'impostazione di visualizzare la risposta corretta e già è stato cliccato il tasto "Prosegui"
                setIndexDomandaProgress(newIndexProgress);
            }
            setIndexDomanda(newIndex);
            let indexQuizQuestionarioCompilato = questionarioCompilato.findIndex(q => q.quizId === sezioni[indexSezione].quiz[indexDomanda].quizId) + 1;
            if (indexQuizQuestionarioCompilato != -1) {
                setSelectedRisposte(questionarioCompilato[indexQuizQuestionarioCompilato].givenAnswer)
            } else setSelectedRisposte([]);
            // setSelectedDomanda('');
            setNextQuestion(false);
        } else { //Se è l'ultima domanda della sezione
            if (indexSezione < sezioni.length - 1) { //Se ci sono altre sezioni, sezioni.length -1 perchè senno crashava
                let indexSezioneTmp = indexSezione;
                let sezioniConTempoMinTrascorsoTmp = sezioniConTempoMinTrascorso;
                sezioniConTempoMinTrascorsoTmp.push(indexSezione);
                setSezioniConTempoMinTrascorso(sezioniConTempoMinTrascorsoTmp)
                setTempoTrascorsoInSezione(0);
                indexSezioneTmp++;
                setIndexSezione(indexSezioneTmp);
                //setTimer(sezioni[indexSezioneTmp].tempMax * 60)
                setIndexDomanda(0);
                let indexQuizQuestionarioCompilato = questionarioCompilato.findIndex(q => q.quizId === sezioni[indexSezione].quiz[indexDomanda].quizId)
                if (indexQuizQuestionarioCompilato != -1) {
                    setSelectedRisposte(questionarioCompilato[indexQuizQuestionarioCompilato].givenAnswer)
                } else setSelectedRisposte([]);
                setNextQuestion(false);

                if (checkPaginationItem.length === 0) {
                    let newIndexProgress = indexDomandaProgress;
                    newIndexProgress++;
                    //Se NON è abilitata l'impostazione di visualizzare la risposta corretta e già è stato cliccato il tasto "Prosegui"
                    setIndexDomandaProgress(newIndexProgress);
                }

                let lastSectionNumber = 0;
                for (let i = 0; i <= indexSezioneTmp; i++) {
                    lastSectionNumber += sezioni[i].quiz.length;
                }
                setLastSectionNumber(lastSectionNumber)
            }
            else { //Se sono finite le sezioni

                let tempoCompletamentoTmp = tempoIniziale - timer;
                tempoCompletamentoTmp = convertMinutesToSeconds(tempoCompletamentoTmp);
                if (checkPaginationItem.length === 0) {
                    let newIndexProgress = indexDomandaProgress;
                    newIndexProgress++;

                    setIndexDomandaProgress(newIndexProgress);
                }
                if (skipCheck == undefined) {
                    setDataFine(new Date().getTime());
                    setStatisticheDialogOpen(true);
                }
                setTempoCompletamento(tempoCompletamentoTmp);
            }
        }



        if ((indexDomanda + 1) > maxPage) {
            setMaxPage(maxPage + pageNumberLimit);
            setMinPage(minPage + pageNumberLimit);
        }
        setCurrentPage(currentPage + 1);
        if (timeFinishedDialogOpen === true)
            setTimeFinishedDialogOpen(false);

        if (showCorrectAnswer === true && nextQuestion === true) {
            setTimeout(() => {
                setNextDomandaButtonDisabled(false)
            }, 500)
        }

    }

    function nextSezioneTimeEnded() {
        let tmpIndexSezione = indexSezione;
        let indexSezioneSuccessiva = indexSezione + 1;

        if (tmpIndexSezione < sezioni.length - 1) { //Se non è l'ultima sezione
            let sezioniTmp = sezioni;
            sezioniTmp[indexSezione].remainingTime = 0;
            setSezioni(sezioniTmp)
            setIndexSezione(indexSezioneSuccessiva);
            setIndexDomanda(0);
            let tmpContatore = 0;
            let lastSectionNumber = 0;

            for (let i = 0; i <= indexSezioneSuccessiva; i++) { //Questo for è diviso dal precedente perchè li c'è un return
                lastSectionNumber += sezioni[i].quiz.length;
            }
            setLastSectionNumber(lastSectionNumber)


            for (let i = 0; i < sezioni.length; i++) {
                for (let j = 0; j < sezioni[i].quiz.length; j++) {

                    if (indexSezioneSuccessiva === i) {
                        setCurrentPage(tmpContatore);
                        setTimeFinishedDialogOpen(false);
                        return;
                    }
                    tmpContatore++;
                }
            }

        }
        else { //Se è l'ultima sezione
            setDataFine(new Date().getTime());
            setStatisticheDialogOpen(true);
        }
        setTimeFinishedDialogOpen(false)
    }
    //Metodi pagination
    function onPageChange(pageNumber) {
        let tmpContatore = 0;

        setCurrentPage(pageNumber);




        for (let i = 0; i < sezioni.length; i++) {
            for (let j = 0; j < sezioni[i].quiz.length; j++) {
                if (tmpContatore === pageNumber) {

                    if (indexSezione !== i) //se c'è un cambio di sezione
                    {
                        let tmpSezioniTmp = sezioni;
                        tmpSezioniTmp[indexSezione].remainingTime = remainingTime;
                        setSezioni(tmpSezioniTmp)
                    }

                    setIndexSezione(i);
                    setIndexDomanda(j);
                    let quizIdTmp = sezioni[i].quiz[j].quizId;
                    let indexQuestionarioCompilato = questionarioCompilato.findIndex(quiz => quiz.quizId === quizIdTmp)
                    if (!isGratuito) {
                        if (typeQuestionario === 'Ese' && !idQuizCompletati.includes(sezioni[i].quiz[j].quizId)) {
                            setNextQuestion(false);
                        }
                        if (typeQuestionario === 'Ese' && idQuizCompletati.includes(sezioni[i].quiz[j].quizId) && showCorrectAnswer) {
                            setNextQuestion(true);
                        }
                    }

                    if (Array.isArray(questionarioCompilato[indexQuestionarioCompilato].givenAnswer))
                        setSelectedRisposte(questionarioCompilato[indexQuestionarioCompilato].givenAnswer)
                    else setSelectedRisposte([questionarioCompilato[indexQuestionarioCompilato].givenAnswer])

                    // setSelectedDomanda(questionarioCompilato[indexQuestionarioCompilato].givenAnswer);
                    titleMobileRef?.current?.click()
                    titlePcRef?.current?.click()

                    return;
                }
                tmpContatore++;
            }
        }
    }


    function onPrevClick() {

        if ((currentPage % pageNumberLimit) === 0) {
            if (maxPage <= pageNumberLimit) {
                setMaxPage(pageNumberLimit);
            } else {
                setMaxPage(maxPage - pageNumberLimit);
            }
            setMinPage(minPage - pageNumberLimit);
        }
        onPageChange(currentPage - 1)


    }
    function onNextClick() {

        setNextQuestion(false);
        //setShowCorrectAnswer(true);
        if ((currentPage + 1) > maxPage) {
            setMaxPage(maxPage + pageNumberLimit);
            setMinPage(minPage + pageNumberLimit);
        }
        onPageChange(currentPage + 1)

    }

    function detectWindowWidth() {
        setWindiwWidth(window.innerWidth)
        if ((windowWidth >= 370) && (windowWidth <= 768)) {
            setMaxPage(9)
            setPageNumberLimit(10)
        }
        else if (windowWidth < 370) {
            setMaxPage(4)
            setPageNumberLimit(5)
        }
        else {
            setMaxPage(19)
            setPageNumberLimit(20)
        }
    }
    window.onbeforeunload = (event) => {
        const e = event || window.event;
        e.preventDefault();
        if (e) {
            e.returnValue = '';
        }
        return '';
    };
    window.onload = () => {
        setIndexDomandaProgress(0)
        setQuestionarioCompilato([])
    }

    return (
        <Box>
            <Helmet>
                <title>{nomeQuestionario}</title>
            </Helmet>
            <Box height={{ xs: 'unset', md: '100%' }} sx={{
                maxWidth: '100vw', minHeight: '100vh',
                background: 'linear-gradient(145deg, rgba(24,102,171,1) 0%, rgba(0,53,99,1) 80%)'
            }}>

                {totalNumberQuiz > 0 ? (
                    quizLoaded === true && (
                        <Box sx={{ height: '100%' }}>
                            {errorQuiz === true && (
                                <Container sx={{
                                    display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
                                    height: '100%'
                                }} maxWidth="lg">
                                    <Typography sx={{ color: 'white', textAlign: 'center' }} variant="h3">C'è stato un errore durante la generazione del quiz</Typography>
                                    {isGratuito === false && (
                                        <Button variant="text" sx={{ color: 'white' }} startIcon={<ArrowBackIcon />} onClick={changePage}>
                                            <Typography variant="subtitle2" display={{ xs: 'flex', md: 'none' }}>Indietro</Typography>
                                            <Typography variant="subtitle2" display={{ xs: 'none', md: 'flex' }}>Torna alla selezione dei simulatori</Typography>
                                        </Button>
                                    )}
                                </Container>
                            )}
                            {errorQuiz === false && (
                                <Container sx={{
                                    px: { xs: 0, md: 'unset' },
                                    display: 'flex', flexDirection: 'column', alignItems: 'space-between', justifyContent: 'center',
                                    height: '100%'
                                }} maxWidth="lg">
                                    <Box px={{ xs: 2, md: 0 }} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', minHeight: '100vh' }}>
                                        <Grid container spacing={2} style={{ marginTop: '5px' }}>    {/*Header*/}
                                            <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}> {/*Ritorna alla sezione precedente */}
                                                {isGratuito === false ? (
                                                    <Button variant="text" sx={{ color: 'white' }} startIcon={<ArrowBackIcon />} onClick={changePage}>
                                                        <Typography variant="subtitle2" display={{ xs: 'flex', md: 'none' }}>Indietro</Typography>
                                                        <Typography variant="subtitle2" display={{ xs: 'none', md: 'flex' }}>Torna alla selezione dei simulatori</Typography>
                                                    </Button>
                                                ) : (
                                                    <Button variant="text" sx={{ color: 'white' }} startIcon={<ArrowBackIcon />} onClick={goBackToQuestionarioGratuito}>
                                                        <Typography variant="subtitle2" display={{ xs: 'flex', md: 'none' }}>Indietro</Typography>
                                                        <Typography variant="subtitle2" display={{ xs: 'none', md: 'flex' }}>Torna alla selezione dei simulatori</Typography>
                                                    </Button>
                                                )}
                                            </Grid>


                                            <Grid item xs={5} sm={4} sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>  {/*Timer*/}
                                                <Timer timer={timer} sezione={sezioni[indexSezione]} typeQuestionario={typeQuestionario}
                                                    changeRemainingTime={changeRemainingTime} onTimeFinished={onTimeFinished} />
                                            </Grid>


                                            <Grid item xs={3} display={{ xs: 'flex', sm: 'none' }} sx={{ justifyContent: 'end', alignItems: 'center', mt: 2 }}> {/*Logo*/}
                                                <img style={{ maxWidth: '90%' }} draggable="false" unselectable="on" alt="Logo Simone Edizioni" loading="lazy" src={"https://edizioni.simone.it/wp-content/uploads/2022/02/logo-simone-2022.png"} />
                                            </Grid>
                                            <Grid item xs={4} display={{ xs: 'none', sm: 'flex' }} sx={{ justifyContent: 'end', alignItems: 'center' }}> {/*Logo*/}
                                                <img style={{ maxWidth: '35%' }} draggable="false" unselectable="on" alt="Logo Simone Edizioni" loading="lazy" src={"https://edizioni.simone.it/wp-content/uploads/2022/02/logo-simone-2022.png"} />
                                            </Grid>
                                        </Grid>


                                        <Box sx={{ my: 1, width: '100%' }}> {/*Domanda e n. domanda */}

                                            {/*Domanda su tablet e desktop */}
                                            {(sezioni[indexSezione]?.quiz[indexDomanda]?.ripresentato !== undefined && sezioni[indexSezione]?.quiz[indexDomanda]?.ripresentato === true) && (
                                                <Chip sx={{ my: 1, width: { xs: '100%', md: 'fit-content' } }} size='small' color="error" label="Quiz sbagliato frequentemente" icon={<Error />} />
                                            )}
                                            <Typography alignItems="center" sx={{ color: grey[400] }} variant='subtitle2'>
                                                Domanda n.{indexDomanda + 1}
                                                {(sezioni[indexSezione].nomeParte !== '' && typeQuestionario === 'Ese') && (' (' + sezioni[indexSezione].nomeParte + ')')}
                                                {!isGratuito && (
                                                    <Button disabled={loadingInvioSegnalazione} size="small" color="warning" variant="text" sx={{ ml: 2, fontSize: '10px' }} onClick={openDialogSegnalazione}>
                                                        <ReportProblemIcon sx={{ mr: 1, fontSize: '15px' }} />
                                                        Invia segnalazione
                                                    </Button>
                                                )}
                                            </Typography>
                                            <Box ref={titlePcRef} display={{ xs: 'none', sm: 'flex' }} sx={{ mt: 1, color: grey[50], fontSize: '1.5rem', fontWeight: 'bold' }} >
                                                <div className='titleContainer' dangerouslySetInnerHTML={{ __html: sezioni[indexSezione].quiz[indexDomanda].title }}></div>
                                            </Box>
                                            {sezioni[indexSezione].quiz[indexDomanda].correctAnswer.length > 1 && (
                                                <Box display={{ xs: 'none', sm: 'flex' }} sx={{ mt: 1 }}>
                                                    <Typography color="white" variant="subtitle2">É possibile selezionare più risposte corrette in questa domanda.</Typography>
                                                </Box>
                                            )}


                                            {/*Domanda su dispositivi mobile */}
                                            {/* <Typography display={{ xs: 'flex', sm: 'none' }} sx={{ color: grey[400], overflowY: 'auto', maxHeight: '20vh' }} variant='body2'>
                                                {(sezioni[indexSezione].nomeParte !== '' && typeQuestionario === 'Ese') && (' (' + sezioni[indexSezione].nomeParte + ')')}
                                            </Typography> */}
                                            <Box ref={titleMobileRef} display={{ xs: 'flex', sm: 'none' }} sx={{ mt: 1, color: grey[50], fontSize: '1.25rem', fontWeight: 'bold' }}>
                                                <div className='titleContainer' dangerouslySetInnerHTML={{ __html: sezioni[indexSezione].quiz[indexDomanda].title }}></div>
                                            </Box>
                                            {sezioni[indexSezione].quiz[indexDomanda].correctAnswer.length > 1 && (
                                                <Box display={{ xs: 'flex', sm: 'none' }} sx={{ mt: 1 }}>
                                                    <Typography color="white" variant="subtitle2">É possibile selezionare più risposte corrette in questa domanda.</Typography>
                                                </Box>
                                            )}
                                            {sezioni[indexSezione].quiz[indexDomanda].title.includes("<img") ? (<Typography sx={{ color: 'white', ml: 1 }} variant="body2">Clicca le immagini per ingrandirle.</Typography>) : null}
                                        </Box>

                                        <Answer domanda={sezioni[indexSezione].quiz[indexDomanda]} showCorrectAnswer={showCorrectAnswer} nextQuestion={nextQuestion} selectDomanda={selectRisposta} selectedRisposte={selectedRisposte} />

                                        <Grid container style={{ margin: '50px 0px 50px 0px' }}> {/*Bottoni conferma/impostazioni e progress bar */}

                                            {(indexDomanda === (sezioni[indexSezione].quiz.length - 1) && ((sezioni[indexSezione].tempMin * 60) - tempoTrascorsoInSezione) > 0 && sezioni[indexSezione].tempMin > 0 && typeQuestionario === 'Ese' && !sezioniConTempoMinTrascorso.includes(indexSezione)) && (
                                                <Grid item xs={12} sx={{ my: 2, textAlign: 'end' }}>
                                                    <Typography variant="subtitle1" sx={{ color: 'white' }}>
                                                        Prima di proseguire alla sezione successiva è necessario attendere &nbsp;
                                                        {calculateTempoRestanteSezione()}
                                                    </Typography>
                                                </Grid>
                                            )}
                                            <Grid item xs={12} md={4} style={{ position: "relative" }}>
                                                <Typography variant="subtitle2" sx={{ textAlign: 'center', color: 'white', position: 'absolute', zIndex: 10, left: '45%', lineHeight: 'unset' }}>
                                                    {indexDomandaProgress}/{totalNumberQuiz}
                                                </Typography>
                                                <BorderLinearProgress variant="determinate" value={progressBarPercentage()} />
                                            </Grid>

                                            <Grid item xs={12} md={8} mt={{ xs: 2, md: 0 }} justifyContent={{ xs: 'space-between', md: 'end' }} sx={{ display: 'flex', width: '100%', flexDirection: { xs: 'column', md: 'row' } }}>
                                                {typeQuestionario === 'Ese' && ( //BOTTONE IMPOSTAZIONI
                                                    <Fragment>
                                                        {/*Bottone impostazioni DISPOSITIVI MOBILE */}
                                                        <Button size="small" variant="text" fullWidth sx={{ mr: 3, mb: 1, display: { xs: 'flex', md: 'none' }, color: 'white' }} onClick={setCorrectAnswer}>
                                                            Mostra risposta corretta  {showCorrectAnswer ? <CheckBoxIcon sx={{ ml: 1 }} /> : <CheckBoxOutlineBlankIcon sx={{ ml: 1 }} />}

                                                            {/*showCorrectAnswer ? 'Non mostrare risposta corretta' : 'Mostra risposta corretta' */}
                                                        </Button>
                                                        {/*Bottone impostazioni DISPOSITIVI MEDIO-GRANDI */}
                                                        <Button size="medium" variant="text" display={{ xs: 'none', md: 'md' }} sx={{ mr: 3, display: { xs: 'none', md: 'flex' }, color: 'white' }} onClick={setCorrectAnswer}>
                                                            Mostra risposta corretta  {showCorrectAnswer ? <CheckBoxIcon sx={{ ml: 1 }} /> : <CheckBoxOutlineBlankIcon sx={{ ml: 1 }} />}

                                                            {/*showCorrectAnswer ? 'Non mostrare risposta corretta' : 'Mostra risposta corretta' */}                                                        </Button>
                                                    </Fragment>
                                                )}

                                                {/*Bottone conferma e prosegui DISPOSITIVI MEDIO-GRANDI */}
                                                {currentPage < questionarioCompilato.length - 1 && (
                                                    <Button size="medium" sx={{ display: { xs: 'none', md: 'flex' } }} color="success" variant="contained"
                                                        disabled={((indexDomanda === (sezioni[indexSezione].quiz.length - 1) && ((sezioni[indexSezione].tempMin * 60) - tempoTrascorsoInSezione) > 0 && sezioni[indexSezione].tempMin > 0 && typeQuestionario === 'Ese' && !sezioniConTempoMinTrascorso.includes(indexSezione))
                                                            || (nextDomandaButtonDisabled && showCorrectAnswer))}
                                                        onClick={() => { nextQuestionCheck(); checkQuizInSection() }}>
                                                        {(showCorrectAnswer === true && !isGratuito) && (
                                                            indexDomanda === (sezioni[indexSezione].quiz.length - 1) ? (
                                                                nextQuestion === false ? 'Visualizza risposta' : 'Conferma e vai alla sezione successiva'
                                                            ) : (
                                                                nextQuestion === false ? 'Conferma e visualizza risposta' : 'Prosegui'
                                                            )
                                                        )}
                                                        {showCorrectAnswer === false && (
                                                            indexDomanda === (sezioni[indexSezione].quiz.length - 1) ? (
                                                                'Conferma e vai alla sezione successiva'
                                                            ) : (
                                                                'Conferma e prosegui'
                                                            )
                                                        )}

                                                    </Button>
                                                )}
                                                {currentPage >= questionarioCompilato.length - 1 && (
                                                    <Button size="medium" color="success" sx={{ display: { xs: 'none', md: 'flex' } }} disabled={(indexDomanda === (sezioni[indexSezione].quiz.length - 1) && (sezioni[indexSezione].tempMin * 60) - tempoTrascorsoInSezione) > 0 && typeQuestionario === 'Ese'}
                                                        variant="contained" onClick={checkForEmptyGivenAnswer}>
                                                        {(showCorrectAnswer === true && !isGratuito) ? (
                                                            indexDomanda === (sezioni[indexSezione].quiz.length - 1) ? (
                                                                nextQuestion === false ? 'Visualizza risposta' : 'Concludi'
                                                            ) : (
                                                                nextQuestion === false ? 'Conferma e visualizza risposta' : 'Concludi'
                                                            )
                                                        ) : (
                                                            "Concludi"
                                                        )}

                                                    </Button>
                                                )}



                                                {/*Bottone conferma e prosegui DISPOSITIVI MOBILE */}
                                                {currentPage < questionarioCompilato.length - 1 && (
                                                    <Button color="success" size="small" fullWidth={typeQuestionario === 'Sim' ? true : false} sx={{ display: { xs: 'flex', md: 'none' } }} variant="contained"
                                                        onClick={() => { nextQuestionCheck(); checkQuizInSection() }} disabled={((indexDomanda === (sezioni[indexSezione].quiz.length - 1) && ((sezioni[indexSezione].tempMin * 60) - tempoTrascorsoInSezione) > 0 && sezioni[indexSezione].tempMin > 0 && typeQuestionario === 'Ese' && !sezioniConTempoMinTrascorso.includes(indexSezione))
                                                            || (nextDomandaButtonDisabled && showCorrectAnswer))}>

                                                        {(showCorrectAnswer === true && !isGratuito) && (
                                                            indexDomanda === (sezioni[indexSezione].quiz.length - 1) ? (
                                                                nextQuestion === false ? 'Visualizza risposta' : 'Conferma e vai alla sezione successiva'
                                                            ) : (
                                                                nextQuestion === false ? 'Conferma e visualizza risposta' : 'Prosegui'
                                                            )
                                                        )}
                                                        {showCorrectAnswer === false && (
                                                            indexDomanda === (sezioni[indexSezione].quiz.length - 1) ? (
                                                                'Conferma e vai alla sezione successiva'
                                                            ) : (
                                                                'Conferma e prosegui'
                                                            )
                                                        )}
                                                    </Button>
                                                )}
                                                {currentPage >= questionarioCompilato.length - 1 && (
                                                    <Button fullWidth color="success" size="medium" sx={{ display: { xs: 'flex', md: 'none' } }} disabled={(indexDomanda === (sezioni[indexSezione].quiz.length - 1) && (sezioni[indexSezione].tempMin * 60) - tempoTrascorsoInSezione) > 0 && typeQuestionario === 'Ese'}
                                                        variant="contained" onClick={checkForEmptyGivenAnswer}>
                                                        {(showCorrectAnswer === true && !isGratuito) ? (
                                                            indexDomanda === (sezioni[indexSezione].quiz.length - 1) ? (
                                                                nextQuestion === false ? 'Visualizza risposta' : 'Concludi'
                                                            ) : (
                                                                nextQuestion === false ? 'Conferma e visualizza risposta' : 'Concludi'
                                                            )
                                                        ) : (
                                                            "Concludi"
                                                        )}
                                                    </Button>
                                                )}


                                            </Grid>
                                            {/* <Button onClick={debugEndTime} variant="contained">Finisci tempo (Sezione successiva)</Button> */}
                                            <Grid item xs={12} sx={{ backgroundColor: 'white', borderRadius: '5px', p: 1, width: '100%', mt: 3 }}>
                                                <Pagination minPage={minPage} maxPage={maxPage} currentPage={currentPage} total={totalNumberQuiz}
                                                    onPrevClick={onPrevClick}
                                                    onNextClick={onNextClick}
                                                    onPageChange={onPageChange}
                                                    quizSection={quizSection}
                                                    lastSectionNumberBool={(indexDomanda === (lastSectionNumber - 1) && convertMinutesToSeconds(remainingTime) > 0) ? true : false}
                                                    lastSectionNumber={lastSectionNumber}
                                                    quizPartenza={quizPartenza}
                                                    nextArrowDisabled={(indexDomanda === (sezioni[indexSezione].quiz.length - 1) && ((((remainingTime / 60) - sezioni[indexSezione].tempMin)).toFixed(2) > 0)) && sezioni[indexSezione].tempMin > 0 && typeQuestionario === 'Ese'}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Container>
                            )}
                        </Box>
                    )
                ) : (
                    quizLoaded === true ? (
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '95vh', justifyContent: 'center' }}>
                            <Typography variant="h3" sx={{ color: 'white', textAlign: 'center' }}>Il simulatore non ha nessun quiz associato.</Typography>
                            {!isGratuito ? (
                                <Button variant="text" sx={{ mr: 3, color: 'white' }} startIcon={<ArrowBackIcon />} onClick={changePage}>
                                    <Typography variant="subtitle2" display={{ xs: 'flex', md: 'none' }}>Indietro</Typography>
                                    <Typography variant="subtitle2" display={{ xs: 'none', md: 'flex' }}>Clicca qui per tornare indietro</Typography>
                                </Button>
                            ) : (
                                <Typography variant="subtitle2" sx={{ color: 'white', textAlign: 'center', mt: 1 }}>Riprova tra qualche minuto</Typography>
                            )}

                        </Box>
                    ) : (
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '95vh', justifyContent: 'center' }}>
                            <CircularProgress
                                variant="indeterminate"
                                color="secondary"
                                disableShrink
                                sx={{
                                    animationDuration: '550ms',
                                    mb: 1,
                                    left: 0,
                                    [`& .${circularProgressClasses.circle}`]: {
                                        strokeLinecap: 'round',
                                    },
                                }}
                                size={40}
                                thickness={4} />
                            <Typography variant="h3" sx={{ color: 'white', textAlign: 'center' }}>Caricamento...</Typography>
                        </Box>
                    )
                )}



                <Dialog open={settingsDialogOpen} > {/*Dialog Impostazioni*/}
                    <DialogTitle>Impostazioni Quiz</DialogTitle>
                    <DialogContent>
                        <FormGroup>
                            <FormControlLabel label="Mostra la risposta corretta all'avanzamento del quiz"
                                control={<Checkbox checked={showCorrectAnswer} onChange={setCorrectAnswer} />}
                            />
                        </FormGroup>
                    </DialogContent>
                    <DialogActions sx={{ m: 2 }}>
                        <Button variant="contained" onClick={closeSettingsDialog} >Chiudi</Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={statisticheDialogOpen} fullScreen>
                    <DialogContent>
                        <StatisticheQuizSvolto dataInizio={dataInizio} dataFine={dataFine} tempo={tempoCompletamento} questionario={questionarioCompilato} isGratuito={isGratuito} nomeQuestionario={nomeQuestionario}
                            idVolume={volumeSelezionatoID} idUtente={idUtente} idQuestionario={questionarioSelezionatoID} type={typeQuestionario} sogliaSufficienza={sogliaSufficienza} />
                    </DialogContent>
                </Dialog>

                <Dialog open={openEndQuizDialog}>
                    <DialogTitle>Ci sono risposte omesse, concludere comunque?</DialogTitle>
                    <DialogActions>
                        <Button onClick={handleDialogClose}>Chiudi</Button>
                        <Button onClick={() => { handleDialogClose(); nextQuestionCheck(); checkQuizInSection() }} autoFocus color="success" variant='contained'>
                            Concludi simulatore
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog maxWidth="md" fullWidth open={dialogSegnalazioneOpen}> {/*Dialog Segnalazioni*/}
                    <DialogContent>
                        <Typography variant='h3'>Segnala un problema</Typography>
                        <TextField
                            value={testoSegnalazione}
                            onChange={changeTextSegnalazione}
                            sx={{ mt: 1 }}
                            fullWidth
                            placeholder='Spiegaci il problema che hai riscontrato'
                            multiline
                            rows={4}
                        />
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end', mt: 2 }}>
                            <Button variant="text" sx={{ mr: 1 }} onClick={openDialogSegnalazione}>Annulla</Button>
                            <Button disabled={loadingInvioSegnalazione} variant='contained' color="success" onClick={sendSegnalazione}>
                                {loadingInvioSegnalazione ? "Invio in corso..." : " Invia segnalazione"}
                            </Button>
                        </Box>
                    </DialogContent>
                </Dialog>

                <Snackbar onClose={closeAlertSegnalazione} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={alertSegnalazioneInviataOpen} autoHideDuration={3000}>
                    <MuiAlert onClose={closeAlertSegnalazione} severity={tipoAlertSegnalazione} sx={{ width: '100%' }}>
                        {tipoAlertSegnalazione == 'success' ? 'Segnalazione inviata' : 'Errore durante l\' invio della segnalazione'}
                    </MuiAlert>
                </Snackbar>

                {!statisticheDialogOpen && (
                    <Dialog open={timeFinishedDialogOpen}>
                        <DialogContent>
                            <Typography>Il tempo a disposizione per rispondere alle domande della sezione selezionata è concluso.</Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button variant="contained" onClick={nextSezioneTimeEnded}>Prosegui</Button>
                        </DialogActions>
                    </Dialog>
                )}


                <Dialog maxWidth="md" fullWidth open={openDialogImageZoom} onClose={() => { setOpenDialogImageZoom(false) }}>
                    <DialogTitle sx={{ p: 0, display: 'flex', justifyContent: 'end' }}>
                        <IconButton onClick={() => { setOpenDialogImageZoom(false) }} >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>

                    <DialogContent style={{ display: 'flex', justifyContent: 'center' }}>
                        <img style={{ width: '100%', maxHeight: '100%' }} src={urlImageToZoom} />
                    </DialogContent>
                </Dialog>



            </Box>
        </Box >
    )
}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 18,
    borderRadius: 8,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: '#196BB4',
        boxShadow: '0px 9px 16px 0px rgb(0 0 0 / 19%)',

    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 8,
        backgroundColor: '#003A6C',

    },
}));


function useInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        let id = setInterval(() => {
            savedCallback.current();
        }, delay);
        return () => clearInterval(id);
    }, [delay]);
}
