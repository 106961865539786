import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import VistaQuiz from './pages/vistaQuiz';
import DashboardUtente from './pages/dashboardUtente';
import ErrorPage from './pages/errorPage';
import { useEffect } from 'react';
import { Box } from '@mui/material';
import CookiePolicy from './pages/privacyPolicy';
import DebugToken from './pages/debugToken';
import Button from '@mui/material/Button';
import ImpostazioniQuestionarioRifatto from './pages/impostazioniQuestionarioRifatto';
import { useState } from 'react';
import SessioneScadutaPage from './pages/sessioneScadutaPage';
import { HelmetProvider } from 'react-helmet-async';
import NewDashboardUtente from './pages/newDashboardUtente';

export default function App() {

  const [cookieAccepted, setCookiesAccepted] = useState(true);

  useEffect(() => {
    if (!process.env.REACT_APP_IS_DEBUG) {
      document.addEventListener('contextmenu', (e) => {
        e.preventDefault();
      });
    }

    if (localStorage.getItem('cookieAccepted') != null) {
      setCookiesAccepted(true);
    } else {
      setCookiesAccepted(false);
    }
  }, [])

  function goToInformativaCookie() {

    let url = "https://edizioni.simone.it/informativa-sulla-privacy-quiz"
    window.open(url, '_blank').focus();
  }

  function acceptCookiesMethod() {
    setCookiesAccepted(true);
    localStorage.setItem('cookieAccepted', "true");
  }

  function abilitaAlertCookie() {
    setCookiesAccepted(false);
    localStorage.setItem('cookieAccepted', "false");
  }

  return (
    <ThemeConfig>
      <HelmetProvider>
        <GlobalStyles />
        {/* <BrowserRouter basename={"/" + process.env.REACT_APP_PREFIX}> */}
        <BrowserRouter basename={process.env.REACT_APP_PREFIX}>

          <Routes>
            <Route index element={<NewDashboardUtente abilitaAlertCookie={abilitaAlertCookie} />} />
            <Route path="home" element={<NewDashboardUtente abilitaAlertCookie={abilitaAlertCookie} />} />
            <Route path="sim/*" element={<ImpostazioniQuestionarioRifatto />} />
            <Route path="quiz/*" element={<VistaQuiz />} />
            <Route path="sessione-scaduta" element={<SessioneScadutaPage />} />
            <Route path="token-debug/*" element={<DebugToken />} />
            <Route path="*" element={<ErrorPage />} />
            <Route path="cookie-policy" element={<CookiePolicy />} />
          </Routes>

        </BrowserRouter>
        {!cookieAccepted && (
          <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'center', alignItems: 'center', px: 2, py: 1 }} className="cookies-eu-banner">
            Per offrire un'esperienza migliore, questo sito utilizza dei cookies tecnici.
            <Button onClick={goToInformativaCookie} variant="text" sx={{ color: 'white', ml: { xs: 0, md: 3 } }}>Scopri di più</Button>
            <Button variant="contained" onClick={acceptCookiesMethod} sx={{ ml: { xs: 0, md: 1 }, mt: { xs: 1, md: 0 } }}>Accetta (cookie tecnici)</Button>
          </Box>)}
      </HelmetProvider>
    </ThemeConfig >
  );
}


